import { useEffect, useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { MuiFileInput } from 'mui-file-input'
import * as Yup from 'yup';
import TextField from '@mui/material/TextField';
import { useSelector, useDispatch } from 'react-redux';
import Style from './style.module.css';
import Autocomplete from '@mui/material/Autocomplete';
import Styles from "../account/style.module.css";
import { invoiceActions, alertActions, userActions } from '_store';
import { Loader } from '../../_components/loader';
// import { update } from '@react-spring/web';

export { AddEditInvoice };


function AddEditInvoice({ handleClose, id }) {

    const [disabled, setDisabled] = useState(false)
    // Add these variables to your component to track the state

    const [title, setTitle] = useState();
    const [label, setLabel] = useState();
    const [merchant, setMerchant] = useState([]);
    const [disabledFiled, setDisabledField] = useState(false)

    const dispatch = useDispatch();
    const location = useSelector(x => x.location.list);
    const user = useSelector(x => x.users.list);

    // form validation rules 
    const validationSchema = Yup.object().shape(
        !id && {
            status: Yup.string()
                .required('status is required'),
            merchant: Yup.string()
                .required('Merchant is required'),
            file: Yup.mixed()
                .required('File is required')
                .test('fileSize', 'File size is too large', (value) => {
                    if (!value) return true; // Allow empty file
                    return value.size <= 5 * 1024 * 1024; // 5MB maximum size
                })
                .test('fileType', 'Unsupported file type', (value) => {
                    if (!value) return true; // Allow empty file
                    return ['image/jpeg', 'image/png','application/pdf'].includes(value.type); // Allowed file types
                }),

        });
    const formOptions = { resolver: yupResolver(validationSchema) };

    // get functions to build form with useForm() hook
    const { register, handleSubmit, control, reset, formState } = useForm(formOptions);
    const { errors, isSubmitting } = formState;
    useEffect(() => {
        dispatch(userActions.getAll());

    }, []);

    useEffect(() => {
        let userData = user?.value?.data?.users
        let data = userData?.map((x) => {
            return `${x.name} | ${x.email}`
        })
        setMerchant(data)
        if (id) {
            setTitle('Edit Invoice');
            setLabel('UPDATE INVOICE');
            setDisabledField(true)
            dispatch(invoiceActions.getAll())
                .then(res => {
                    const invoiceArr = res?.payload?.data?.invoices
                    let item = invoiceArr.filter((c) => {
                        const obj = c._id === id
                        return obj
                    })
                    let obj = {
                        "merchant": item[0].merchant.email,
                        "status": item[0].status,
                        'price': item[0].price
                    }
                    // console.log(invoiceArr);
                    reset(obj)
                })
        } else {
            setTitle('Add New Invoice');
            setLabel('ADD INVOICE');
        }
    }, [user]);

    async function onSubmit(data) {
        console.log("file", data);
        dispatch(alertActions.clear());
        setDisabled(true)
        try {
            // create or update user based on id param
            if (id) {
                let invoiceId = id
                let obj = {
                    "status": data.status,
                }
                const updateInvoice = await dispatch(invoiceActions.update({ invoiceId, obj })).unwrap();
                if (updateInvoice.success === true) {
                    const message = updateInvoice?.data?.message
                    // console.log("message", updateInvoice);
                    handleClose(false)
                    dispatch(alertActions.success({ message, showAfterRedirect: true }));
                }
            } else {
                const merchantEmail = data.merchant.split('|')[1].trim()
                console.log("merchantEmail", merchantEmail);
                let mercahntID = user.value.data.users.filter((x) => {
                    const id = x.email === merchantEmail
                    return id
                })

                console.log("mercahntID", mercahntID);

                let dataObj = {
                    "merchant": mercahntID[0]._id,
                    'invoice': data.file,
                    'price': data.price 
                }
                const copyData = { ...dataObj }
                const formData = new FormData();
                Object.keys(copyData).forEach((key) => {
                    formData.append(key, copyData[key]);
                })
                console.log("data", data);
                const addInvoice = await dispatch(invoiceActions.addInvoice({ formData })).unwrap();

                if (addInvoice.status === 200) {
                    const message = addInvoice?.data?.data?.message
                    handleClose(false)
                    dispatch(alertActions.success({ message, showAfterRedirect: true }));
                }
            }
            // redirect to invoice list with success message
            dispatch(invoiceActions.getAll());
        } catch (error) {
            dispatch(alertActions.error(error));
        }
        setTimeout(() => {
            setDisabled(false)
        }, 2500);
    }


    return (
        <div className={Style.sections_edit}>
            <div className={Style.main_container_edit}>
                <h1 style={{ textAlign: "center" }} className={Style.title}>{title}</h1>
                {!(location?.loading || location?.error) &&
                    <form onSubmit={handleSubmit(onSubmit)} style={{ marginTop: "50px" }}>
                        <div className={Styles.input_wrapper}>
                            <Autocomplete
                                disabled={disabledFiled}
                                freeSolo
                                options={merchant}
                                renderInput={(params) => (
                                    <TextField
                                        disabled={disabledFiled}
                                        {...register("merchant")} {...params} label={!id && 'select merchant'} id="filled-basic" variant="filled" />
                                )}
                            />
                            <div className={Styles.invalid_feedback}>{errors.merchant?.message}</div>
                        </div>


                        <div className={Styles.input_row}>
                            <div className={Styles.input_wrapper}>
                                <Controller
                                    name="file"
                                    control={control}
                                    render={({ field }) => (
                                        <>
                                            <MuiFileInput
                                                id="file"
                                                variant="filled"
                                                label="Select invoice"
                                                disabled={disabledFiled}
                                                {...field}
                                            />
                                            <div className={Styles.invalid_feedback} >{errors.file?.message}</div>
                                        </>
                                    )}
                                />
                            </div>
                            <div className={Styles.input_wrapper}>
                            <TextField
                                disabled={disabledFiled}
                                {...register("price")} label={!id && 'Add Amount'} id="filled-basic" variant="filled" />
                            </div>
                        </div>
                        <div className={Styles.input_row}>
                            <div className={Styles.input_wrapper}>
                                <div className={Style.input_container}>
                                    <div className={Style.flex}>
                                        <span className={Style.input_label}>
                                            Invoice status
                                        </span>
                                        <select className={Style.input_order} name='status' placeholder='Add Status' {...register("status")}>
                                            <option value="pending">Pending</option>
                                            <option value="paid">Paid</option>
                                            <option value="overdue">Overdue</option>
                                        </select>
                                    </div>

                                    <div className={Styles.invalid_feedback}>{errors.name?.message}</div>
                                </div>
                            </div>
                        </div>
                        <div className={Styles.input_row} style={{ gap: "20px", marginTop: "20px" }}>
                            <button type="submit" disabled={disabled} className={Styles.theme_btn}>
                                {isSubmitting && disabled && <span className="spinner"></span>}
                                {label}
                            </button>
                        </div>
                    </form>
                }
                {location?.loading &&
                    <div style={{ textAlign: "center", marginTop: "40px" }}>
                        <Loader />
                    </div>
                }
                {location?.error &&
                    <div class="text-center m-5">
                        <div class="text-danger">Error loading user: {location.error}</div>
                    </div>
                }
            </div>
        </div>
    );
}
