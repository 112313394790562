import {
  Routes,
  Route,
  Navigate,
  useNavigate,
  useLocation,
} from "react-router-dom";
import { history } from "_helpers";
import { Nav, Alert, PrivateRoute, Sidebar } from "_components";
import { OrderLayout } from "_screen/orders";
import { AccountLayout } from "_screen/account";
import { UsersLayout } from "_screen/merchants";
import { InvoiceLayout } from "_screen/invoice";
import { LocationLayout } from "_screen/location";
// import  UpdateProfile  from "_screen/account/UpdateProfile";
import  UpdateProfile  from "_screen/account/UpdateProfile";
import { userActions } from "_store";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

export { App };

function App() {
  // init custom history object to allow navigation from
  // anywhere in the react app (inside or outside components)
  //CHECK IF ADMIN
  const dispatch = useDispatch();
  const auth = useSelector((x) => x.auth.value);
  const [isAdmin, setIsAdmin] = useState(false);
  const isLogin = useSelector((state) => state.auth.value);

  useEffect(() => {
    if (isLogin !== null) {
      dispatch(userActions.currentMerchant()).then((data) => {
        // console.log("data app", data);
        const role = data?.payload?.data?.user?.role;
        role === "admin" ? setIsAdmin(true) : setIsAdmin(false);
        localStorage.setItem("role", role);
      });
    }
  }, [auth]);

  useEffect(() => {
    // console.log("isAdmin", isAdmin);
  }, [isAdmin]);

  history.navigate = useNavigate();
  history.location = useLocation();

  return (
    <div className="main_container flex">
      <Alert />
      <Sidebar />

      <Nav />
      <div className="main_content">
        <Routes>
          {/* private */}
          {isAdmin ? (
            <Route element={<PrivateRoute />}>
              <Route path="/*" element={<OrderLayout />} />
              <Route path="users/*" element={<UsersLayout />} />
              <Route path="invoice/*" element={<InvoiceLayout />} />
              {/* <Route path="location/*" element={<LocationLayout />} /> */}
            </Route>
          ) : (
            <Route element={<PrivateRoute />}>
              <Route path="/*" element={<OrderLayout />} />
              <Route path="users/*" element={<Navigate to="/" />} />
              <Route path="location/*" element={<LocationLayout />} />
              <Route path="invoice/*" element={<InvoiceLayout />} />
              <Route
                path="account/update-profile"
                element={<UpdateProfile />}
              />
              <Route path="*" element={<Navigate to="/" />} />
            </Route>
          )}

          {/* public */}
          <Route path="account/*" element={<AccountLayout />} />
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
      </div>
    </div>
  );
}
