import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { userActions } from '_store';
import MenuIcon from '@mui/icons-material/Menu';
import './Navbar.css';

import { mobileActions } from '_store';

export { Nav };

function Nav() {
    const [userData, setUserData] = useState()
    const dispatch = useDispatch();
    const auth = useSelector(x => x.auth.value);
    console.log("auth",auth);

    useEffect(() => {
        dispatch(userActions.currentMerchant())
            .then((data) => {
                setUserData(data?.payload?.data?.user)
            })
    }, [auth]);
    useEffect(() => {
        //
    }, [userData])

    console.log("userData", userData);
    // only show nav when logged in
    if (!auth) return null;

    return (
        <nav className="navBar flex align_c ">
            <div className="nav_logo align_c" style={{ textAlign: "center" }}>
                <Link className='logo' to="/">
                    <img
                        src="/images/ihips_new_logo.png"
                        alt="Logo"
                        width="150px"
                        height="50px"
                    />
                </Link>
                <div>
                    <MenuIcon className='menu_bars' onClick={() => { dispatch(mobileActions.showMobile("showOnMobile")) }} />
                </div>
            </div>
            <div className="flex align_c navBar_content">
                <h6 className='display_name'>{userData?.businessName}</h6>
                <Link to="account/update-profile">
                <img
                    src={`${process.env.REACT_APP_BACKEND_URL}${userData?.businessLogo}`}
                    className='bLogo'
                    alt="Logo"
                />
                </Link>
                {/* <span className='avatar flex align_c justify_c'>{userData?.name.slice(0, 1)}</span> */}
            </div>
        </nav>
    );
}