import * as React from 'react';
import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import Style from './style.module.css';
import AddIcon from '@mui/icons-material/Add';
import Box from '@mui/material/Box';
import { DataGrid } from '@mui/x-data-grid';
import PropTypes from 'prop-types';
import Backdrop from '@mui/material/Backdrop';
import Modal from '@mui/material/Modal';
import CloseIcon from '@mui/icons-material/Close';
import { useSpring, animated } from '@react-spring/web';
import { AddEditInvoice } from './AddEditInvoice';
import { invoiceActions, userActions } from '_store';
import SearchIcon from '@mui/icons-material/Search';
import Input from '@mui/material/Input';
import moment from 'moment';


export { Invoice };
//<Model Styling>
const Fade = React.forwardRef(function Fade(props, ref) {
	const {
		children,
		in: open,
		onClick,
		onEnter,
		onExited,
		ownerState,
		...other
	} = props;
	const style = useSpring({
		from: { opacity: 0 },
		to: { opacity: open ? 1 : 0 },
		onStart: () => {
			if (open && onEnter) {
				onEnter(null, true);
			}
		},
		onRest: () => {
			if (!open && onExited) {
				onExited(null, true);
			}
		},
	});

	return (
		<animated.div ref={ref} style={style} {...other}>
			{React.cloneElement(children, { onClick })}
		</animated.div>
	);
});

Fade.propTypes = {
	children: PropTypes.element.isRequired,
	in: PropTypes.bool,
	onClick: PropTypes.any,
	onEnter: PropTypes.func,
	onExited: PropTypes.func,
	ownerState: PropTypes.any,
};
//<Model Styling/>
function Invoice() {
	const [open, setOpen] = React.useState(false);
	const [rows, setRows] = React.useState([]);
	const [editInvoice, setEditInvoice] = React.useState('');
	const handleOpen = () => setOpen(true);
	const handleClose = () => setOpen(false);
	const mobile = useSelector(x => x.mobile.value)
	const invoice = useSelector(x => x.invoice.list);
	const auth = useSelector(x => x.auth.value);
	const [isAdmin, setIsAdmin] = React.useState(false);

	//INVOICE SEARCH FILTER
	const [searchValue, setSeatchValue] = React.useState('');
	const [search, setSearch] = React.useState(searchValue);

	const handleChange = (event) => {
		setSeatchValue(event.target.value);
	};
	const handleClick = () => {
		setSearch(searchValue);
	};

	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(invoiceActions.getAll());
	}, []);

	//SET GRID ROW
	useEffect(() => {
		const invoiceData = invoice?.value?.data?.invoices
		// console.log("Invoice Data", invoiceData);
		if (search) {
			const filterData = invoiceData?.filter(x => {
				const filteredObj = x.invoiceNo.includes(search);
				return filteredObj;
			});

			if (invoice?.value?.data?.invoices.length > 0) {
				const invoiceFiltered = filterData.map(data => {
					return {
						"id": data._id, "idInvoice": data.invoiceNo, "price": data.price ? `$ ${data?.price}` : '', 'merchant': data.merchant.name, "status": data.status,
						'date': moment(data.createdAt).format('YYYY-MM-DD HH:mm'), "link": `${process.env.REACT_APP_BACKEND_URL}${data.fileLink}`,
						url: `edit/${data.id}`
					}
				})
				setRows(invoiceFiltered)
			}
		}
		else {
			if (invoiceData?.length > 0) {
				const items = invoiceData?.map((data, i) => {
					return {
						"id": data._id, "idInvoice": data.invoiceNo, "price": data.price ? `$ ${data?.price}` : '', 'merchant': data.merchant.name, "status": data.status,
						'date': moment(data.createdAt).format('YYYY-MM-DD HH:mm'), "link": `${process.env.REACT_APP_BACKEND_URL}${data.fileLink}`,
						url: `edit/${data.id}`
					}
				})
				setRows(items)
			}
		}


	}, [invoice, search]);

	const handleDownloadClick = (cellValues) => {
		// console.log("cellValues", cellValues);
		const link = document.createElement('a');
		link.href = cellValues;
		link.target = "_blank";
		link.download = 'invoice.png';
		link.click();
	};

	//AUTHERTICATE USER
	useEffect(() => {
		dispatch(userActions.currentMerchant())
			.then((data) => {
				const role = data?.payload?.data?.user?.role
				role === 'admin' ? setIsAdmin(true) : setIsAdmin(false)
			})
	}, [auth]);
	useEffect(() => {
		// console.log("isAdmin", isAdmin);
	}, [isAdmin])

	//GRID COLUMN
	const columns = [
		{
			field: 'idInvoice', headerName: 'Invoice ID',
			minWidth: 70,
			// maxWidth: 300,
			flex: .5,
		},
		{
			field: 'merchant',
			headerName: 'Merchant',
			minWidth: 100,
			flex: .8
		},
		{
			field: 'date',
			headerName: 'Invoice Date',
			minWidth: 120,
			flex: .8
		},
		{
			field: 'price',
			headerName: 'Amount',
			minWidth: 100,
			flex: .8
		},
		{
			field: 'status',
			headerName: 'Status',
			minWidth: 150,
			flex: 1,
			align: 'left',
			headerAlign: 'left',
			renderCell: (params) => (
				<span className={`${params.value.replaceAll(' ', '')} status`}>{params.value}</span>
			)
		},
		isAdmin ? {
			field: "action",
			headerName: "Action",
			renderCell: (cellValues) => {
				return <Link onClick={() => { setEditInvoice(cellValues.row); handleOpen() }} className={Style.col_btn}>Edit</Link>;
			},
			minWidth: 150,
			filterable: false
		}
			:
			{
				field: "action",
				headerName: "Action",
				renderCell: (cellValues) => {
					// console.log(cellValues);
					// return <a className={Style.col_btn} href={`${cellValues.row.link}`} >Download</a>;
					return <button className={Style.col_btn} onClick={() => handleDownloadClick(cellValues.row.link)} > Download</button >;
				},
				minWidth: 150,
				filterable: false
			}
	];

	// Grid Style
	const grigStyle =
	{
		'.MuiDataGrid-columnSeparator': {
			display: 'none',
		},
		".MuiDataGrid-overlayWrapperInner": {
			maxHeight: "500px",
			minHeight: "500px"
		},
		'.MuiDataGrid-overlayWrapper': {
			height: "100%!important"
		},
		'&.MuiDataGrid-root': {
			border: '',
		},
		'.MuiDataGrid-cellContent': {
			fontFamily: "AxiformaRegular",
			fontSize: "14px",
			color: '#303030'
		},
		'.MuiDataGrid-columnHeaderTitleContainerContent': {
			fontFamily: 'AxiformaSemiBold',
			color: '#000',
			fontSize: "14px",
		}
	}

	function NoRowsOverlay() {
		return (
			<div className={Style.container_Notfound}>
				<div className={Style.notFound}></div>
			</div>
		);
	}

	function NoResultsOverlay() {
		return (
			<div className={Style.container_Notfound}>
				<div className={Style.notFound}></div>
			</div>
		);
	}

	return (
		<div className={Style.sections} style={{ marginLeft: mobile ? mobile.class : "290px" }}>
			<div className={Style.main_container} >
				<div className={Style.head}>
					<h1 className={Style.title}>Invoice</h1>
					<div className={Style.add_search}>
						<div className={Style.search}>
							<Input
								sx={{ height: "100%", width: "100%", padding: "10px" }}
								placeholder="Search by Invoice ID"
								onChange={handleChange}
								value={searchValue}
							/>
							<div onClick={handleClick} className={Style.serach_box} >
								<SearchIcon className={Style.search_icons} /></div>
						</div>
						{isAdmin &&
							<Link onClick={() => {
								setEditInvoice('')
								handleOpen()
							}} className={Style.add_invoice}>
								<AddIcon color='#fff' />
								<span className={Style.link}>Add new invoice</span>
							</Link>
						}
					</div>

				</div>

				<div className={Style.userlist}>
					<Box sx={{ height: "100%", width: '100%', background: "#fff" }}>
						<DataGrid
							headerHeight={80}
							rowHeight={80}
							sx={grigStyle}
							className={Style.data}
							rows={rows}
							columns={columns}
							components={{ NoRowsOverlay, NoResultsOverlay }}
							initialState={{
								pagination: {
									paginationModel: {
										pageSize: 10,
									},
								},
							}}
							pageSizeOptions={[5, 10, 15]}
							checkboxSelection
							disableRowSelectionOnClick
						/>
					</Box>
				</div>
			</div>
			<Modal
				aria-labelledby="spring-modal-title"
				aria-describedby="spring-modal-description"
				open={open}
				onClose={handleClose}
				closeAfterTransition
				slots={{ backdrop: Backdrop }}
				slotProps={{
					backdrop: {
						TransitionComponent: Fade,
					},
				}}
			>
				<Fade in={open}>
					<Box className={Style.modelBox}>
						<CloseIcon className={Style.closeBtn} onClick={handleClose} />
						<AddEditInvoice handleClose={handleClose} id={editInvoice ? editInvoice.id : ""} />
					</Box>
				</Fade>
			</Modal>
		</div>
	);
}
