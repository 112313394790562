import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { alertActions } from '_store';

// import { authActions } from '_store';
import { fetchWrapper } from '_helpers';

// create slice

const name = 'users';
const initialState = createInitialState();
const extraActions = createExtraActions();
const extraReducers = createExtraReducers();
const slice = createSlice({ name, initialState, extraReducers });

// exports

export const userActions = { ...slice.actions, ...extraActions };
export const usersReducer = slice.reducer;

// implementation

function createInitialState() {
    return {
        list: null,
        item: null
    }
}

function createExtraActions() {
    const baseUrl = `${process.env.FRONTEND_URL_PORT}/users`;
    // const baseUrl = `${process.env.BACKEND_URL}`;

    return {
        register: register(),
        addUser: addUser(),
        getAll: getAll(),
        getById: getById(),
        update: update(),
        currentMerchant: currentMerchant(),
        // delete: _delete(),
        deleteMerchants: deleteMerchants()
    };


    function register() {
        return createAsyncThunk(
            `${name}/register`,
            async (user, { dispatch }) => {
                const copyData = { ...user }
                // console.log({ copyData });
                const formData = new FormData();
                Object.keys(copyData).forEach((key) => {
                    if (key === "businessLogo") {
                        console.log(copyData, "---->");
                        formData.append(key, copyData[key][0]);
                    } else {
                        formData.append(key, copyData[key]);
                    }
                })
                try {
                    return axios.post(`${process.env.REACT_APP_BACKEND_URL}/auth/register`, formData, {
                        headers: {
                            Accept: "application/json",
                            // Authorization: `eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJfaWQiOiI2NGI1MTYyYWE2OGRjYzkzYTNmNWY0ZmIiLCJpYXQiOjE2OTAzODc3ODZ9.y58Xol-HcfYnynRsy1ubo-OeWqm9jKSAKVwJG-z4I38`,
                            "Content-Type": "multipart/form-data",
                        }
                    })
                        .then(response => {
                            console.log('in response----', response);
                            if (response.status === 200) {
                                return response
                            }
                        })
                        .catch(error => {
                            console.log('in error---', error);
                            let message = error.response.data.message
                            dispatch(alertActions.error(message));
                            return error
                        });

                } catch (error) {
                    dispatch(alertActions.error(error));
                }

            }
            // async (user, type) => await fetchWrapper.post(`${process.env.REACT_APP_BACKEND_URL}/auth/register`, user, type)
            // async (user, type) => await fetchWrapper.post(`${process.env.REACT_APP_BACKEND_URL}/auth/register`, user, type)

        );
    }

    function addUser() {
        return createAsyncThunk(
            `${name}/addUser`,
            async ({ formData }, { dispatch }, _) => {
                console.log('In fetch wrapper', { formData });
                const auth = JSON.parse(localStorage.getItem("auth"))
                const token = auth?.data?.token
                return axios.post(`${process.env.REACT_APP_BACKEND_URL}/merchant`, formData, {
                    headers: {
                        Accept: "application/json",
                        Authorization: `${token}`,
                        "Content-Type": "multipart/form-data",
                    }
                })
                    .then(response => {
                        console.log(response);
                        return response
                    })
                    .catch(error => {
                        console.log(error);
                        let message = error.response.data.message
                        dispatch(alertActions.error(message));
                        return error
                    });
            }
        );
    }

    function currentMerchant() {
        return createAsyncThunk(
            `${name}/currentMerchant`,
            async () => await fetchWrapper.get(`${process.env.REACT_APP_BACKEND_URL}/merchant/get`)
        );
    }

    function getAll() {
        // console.log("token", token);
        return createAsyncThunk(
            `${name}/getAll`,
            async () => await fetchWrapper.get(`${process.env.REACT_APP_BACKEND_URL}/merchant`)
        );
    }
    function getById() {
        return createAsyncThunk(
            `${name}/getById`,
            async (id) => await fetchWrapper.get(`${baseUrl}/${id}`)
        );
    }

    function update() {
        return createAsyncThunk(
            `${name}/update`,
            async function ({ id, formData }) {
                console.log(id, formData);
                return axios.put(`${process.env.REACT_APP_BACKEND_URL}/merchant/${id}`, formData, {
                    headers: {
                        Accept: "application/json",
                        Authorization: `eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJfaWQiOiI2NGI1MTYyYWE2OGRjYzkzYTNmNWY0ZmIiLCJpYXQiOjE2OTAzODc3ODZ9.y58Xol-HcfYnynRsy1ubo-OeWqm9jKSAKVwJG-z4I38`,
                        "Content-Type": "multipart/form-data",
                    }
                })
                    .then(response => {
                        console.log(response);
                        return response
                    })
                    .catch(error => {
                        console.log(error);
                        return error
                    });
                // await fetchWrapper.put(`${process.env.REACT_APP_BACKEND_URL}/merchant/${id}`, formData);
            }
        );
    }

    function deleteMerchants() {
        return createAsyncThunk(`${name}/delete`, async function (obj) {
          console.log(obj);
          await fetchWrapper.post(
            `${process.env.REACT_APP_BACKEND_URL}/merchant/delete`,
            obj
          );
        });
      }


    // function register() {
    //     return createAsyncThunk(
    //         `${name}/register`,
    //         async (user) => await fetchWrapper.post(`${baseUrl}/register`, user)    
    //     );
    // }
    // function getAll() {
    //     return createAsyncThunk(
    //         `${name}/getAll`,
    //         async () => await fetchWrapper.get(baseUrl)
    //     );
    // }


    // function update() {
    //     return createAsyncThunk(
    //         `${name}/update`,
    //         async function ({ id, data }, { getState, dispatch }) {
    //             await fetchWrapper.put(`${baseUrl}/${id}`, data);

    //             // update stored user if the logged in user updated their own record
    //             const auth = getState().auth.value;
    //             if (id === auth?.id.toString()) {
    //                 // update local storage
    //                 const user = { ...auth, ...data };
    //                 console.log("user-0-: ", user);
    //                 localStorage.setItem('auth', JSON.stringify(user));

    //                 // update auth user in redux state
    //                 dispatch(authActions.setAuth(user));
    //             }
    //         }
    //     );
    // }


    // prefixed with underscore because delete is a reserved word in javascript
    // function _delete() {
    //     return createAsyncThunk(
    //         `${name}/delete`,
    //         async function (id, { getState, dispatch }) {
    //             await fetchWrapper.delete(`${baseUrl}/${id}`);

    //             // auto logout if the logged in user deleted their own record
    //             if (id === getState().auth.value?.id) {
    //                 dispatch(authActions.logout());
    //             }
    //         }
    //     );
    // }
}

function createExtraReducers() {
    return (builder) => {
        getAll();
        getById();
        getcurrent();
        // _delete();

        function getAll() {
            var { pending, fulfilled, rejected } = extraActions.getAll;
            builder
                .addCase(pending, (state) => {
                    state.list = { loading: true };
                })
                .addCase(fulfilled, (state, action) => {
                    state.list = { value: action.payload };
                })
                .addCase(rejected, (state, action) => {
                    state.list = { error: action.error };
                });
        }
        function getcurrent() {
            var { pending, fulfilled, rejected } = extraActions.currentMerchant;
            builder
                .addCase(pending, (state) => {
                    state.list = { loading: true };
                })
                .addCase(fulfilled, (state, action) => {
                    state.list = { value: action.payload };
                })
                .addCase(rejected, (state, action) => {
                    state.list = { error: action.error };
                });
        }

        function getById() {
            var { pending, fulfilled, rejected } = extraActions.getById;
            builder
                .addCase(pending, (state) => {
                    state.item = { loading: true };
                })
                .addCase(fulfilled, (state, action) => {
                    state.item = { value: action.payload };
                })
                .addCase(rejected, (state, action) => {
                    state.item = { error: action.error };
                });
        }

        // function _delete() {
        //     var { pending, fulfilled, rejected } = extraActions.delete;
        //     builder
        //         .addCase(pending, (state, action) => {
        //             const user = state.list.value.find(x => x.id === action.meta.arg);
        //             user.isDeleting = true;
        //         })
        //         .addCase(fulfilled, (state, action) => {
        //             state.list.value = state.list.value.filter(x => x.id !== action.meta.arg);
        //         })
        //         .addCase(rejected, (state, action) => {
        //             const user = state.list.value.find(x => x.id === action.meta.arg);
        //             user.isDeleting = false;
        //         });
        // }
    }
}
