import { useEffect, useState, useRef } from "react";
import QRcode from "qrcode.react";
import QrCode2Icon from "@mui/icons-material/QrCode2";
// import AllInclusiveIcon from "@mui/icons-material/AllInclusive";
import { useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import { Link, useLocation } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import { useSelector, useDispatch } from "react-redux";
import Style from "./style.module.css";
import Styles from "../account/style.module.css";
import { orderActions, alertActions } from "_store";
import { Loader } from "../../_components/loader";
// import { history } from "_helpers";
import { jsPDF } from "jspdf";
import { UsersLayout } from "_screen/merchants";

export { EditOrder };

function EditOrder({ handleClose, id }) {
  const orderId = useParams().id;
  const [label, setLabel] = useState("");
  const [title, setTitle] = useState("");
  const mobile = useSelector((x) => x.mobile.value);
  const [disabled, setDisabled] = useState(false);
  const [lastphone, setLastPhone] = useState();
  const [order, setOrder] = useState(false);
  const [role, setRole] = useState("");
  const [merchant, setMerchant] = useState("");
  const [receiverInfo, setReceiverInfo] = useState({
    receiverName: "",
    contactNumber: "",
    address: "",
  });

  const location = useLocation();
  useEffect(() => {
    setRole(localStorage.getItem("role"));
    const queryParams = new URLSearchParams(location.search);
    const param1 = queryParams.get("merchant");
    setMerchant(param1);
  }, []);

  // console.log(merchant);

  const qrCodeRef = useRef(null);
  const generateQRCodeData = () => {
    // console.log(receiverInfo);
    return JSON.stringify(receiverInfo);
  };

  //DOWNLOAD QR DOCE
  const downloadQR = () => {
    // const canvas = document.getElementById("myqr");

    const pdfWidth = 4;
    const pdfHeight = 6;
    const pdf = new jsPDF({
      unit: "in",
      format: [pdfWidth, pdfHeight],
    });

    // const imgData = canvas.toDataURL("image/png");
    // const imgWidth = pdfWidth - 0.5;
    // const imgHeight = (canvas.height * imgWidth) / canvas.width;

    // // Set the desired height for the PDF page

    // // Calculate the coordinates to center the image on the reduced-height page
    // const x = 0.25;
    // const y = 0.9;

    // // Add the image to the PDF
    // pdf.addImage(imgData, "PNG", x, y, imgWidth, imgHeight, "left");

    // // Add some data to the PDF i.e receiver name, contact number and address

    // // const auth = JSON.parse(localStorage.getItem("auth"));
    // const from = order?.user?.businessLocation || order?.user?.businessAddress;
    // pdf.setFontSize(12);
    // pdf.text(`FROM: `, x, 0.3);
    // pdf.setFontSize(10);
    // const fromText = pdf.splitTextToSize(`${from}`, imgWidth);
    // pdf.text(`${order?.user?.businessName}`, x, 0.45);
    // pdf.text(`${order?.user?.phoneNo}`, x, 0.62);
    // pdf.text(fromText, x, 0.8, null, null, "left");
    // console.log(receiverInfo);
    // pdf.setFontSize(12);
    // pdf.text(`TO:`, x, y + imgHeight + 0.1);
    // pdf.setFontSize(10);
    // pdf.text(`${receiverInfo.receiverName}`, x, y + imgHeight + 0.3);
    // const toText = pdf.splitTextToSize(`${receiverInfo.addres}`, imgWidth);
    // pdf.text(toText, x, y + imgHeight + 0.45);

    // // Save the PDF
    // pdf.save(`Label_${order.orderNo}.pdf`);

    const from = order?.user?.businessLocation || order?.user?.businessAddress;
    pdf.setFontSize(10);
    const fromText = pdf.splitTextToSize(`${from}`, 1.5);
    pdf.text(`${order?.user?.businessName}`, 0.25, 0.45);
    pdf.text(`${order?.user?.phoneNo}`, 0.25, 0.62);
    pdf.text(fromText, 0.25, 0.8, null, null, "left");
    pdf.setFontSize(16);
    pdf.text(`${order.shipping_address.name}`, 1, 2);
    const toText = pdf.splitTextToSize(
      `${order.shipping_address.address1 ||
      `${order.shipping_address.city}, ${order.shipping_address.province}, ${order.shipping_address.country}, ${order.shipping_address.zip}`
      }`,
      1.5
    );
    pdf.text(toText, 1, 2.3);

    pdf.setFontSize(16);
    pdf.setFont("courier", "bold");
    pdf.text(`${order.orderNo}`, 3, 0.5);

    pdf.save(`Label_${order.orderNo}.pdf`);
  };

  const dispatch = useDispatch();
  const orders = useSelector((x) => x.order.list);

  // form validation rules
  const validationSchema = Yup.object().shape({
    orderNo: Yup.string().required("Order No is required"),
    amount: Yup.string().required("Subtotal Price/Amount is required"),
  });
  const formOptions = { resolver: yupResolver(validationSchema) };

  // get functions to build form with useForm() hook
  const { register, handleSubmit, reset, formState, setValue } = useForm(formOptions);
  const { errors, isSubmitting } = formState;
  const [modifiedphone, setmodifiedphone] = useState("")
  useEffect(() => {
    if (orderId) {
      let searchObj = {
        search: "",
        status: "",
        date: [],
      };
      dispatch(orderActions.getAll(searchObj)).then((data) => {
        // console.log(data);
        const orderArr = data?.payload?.data?.orders;
        let item = orderArr?.filter((c) => {
          const orderObj = c._id === orderId;
          return orderObj;
        });

        // console.log("orderArr", item);
        console.log("orderArr", item[0]);
        setReceiverInfo({
          receiverName: `${item[0]?.customer?.first_name} ${item[0]?.customer?.last_name}`,
          contactNumber: `${item[0]?.customer?.phone || `N/A`}`,
          addres: `${item[0]?.shipping_address?.address1 ||
            `${item[0]?.shipping_address.city}, ${item[0]?.shipping_address.province}, ${item[0]?.shipping_address.country}, ${item[0]?.shipping_address.zip}` ||
            `N/A`
            }`,
        });
        reset(item[0]);
        setOrder(item[0]);
        setmodifiedphone(convertNumber(item[0] && item[0].shipping_address ? item[0].shipping_address.phone : ""))
        setLabel("Update");
        setTitle("Update order");
      });
    } else {
      setLabel("Create");
      setTitle("Create order");
    }
  }, []);

  function convertNumber(data) {
    var numericString = data.replace(/[^\d]/g, '');
    if (numericString.length > 10) {
      return numericString;
    }
    if (numericString && numericString.length) {
    }
    if (modifiedphone && modifiedphone.length) {
    }
    if (lastphone && lastphone.length) {
    }
    if (modifiedphone && data.length < modifiedphone.length) {
      if (modifiedphone && modifiedphone.length && modifiedphone.charAt(modifiedphone.length - 1) == "-") {
        return `${data.slice(0, data.length - 1)}`;
      }
      else {
        return `${data.slice(0, data.length)}`;
      }
    }
    else {
      if (numericString.charAt(0) == '1') {
        numericString = numericString.slice(1);
      }
      if (numericString.length >= 2) {
        setLastPhone(numericString)
        return `1 (${numericString.slice(0, 3)}) ${numericString.slice(3, 6)}-${numericString.slice(6)}`;
      }
      if (numericString.length >= 2) {
        if (numericString.charAt(0)) {
          numericString = numericString.slice(1);
        }
        var format2 = ''
        var format3 = ''
        if (numericString.length > 3) {
          format2 = numericString.slice(3, 6)
          format3 = numericString.slice(6)
        }

        if (lastphone && lastphone.length) {
        }
        if (modifiedphone && modifiedphone.length) {
        }
        if (modifiedphone && modifiedphone.length && lastphone && lastphone.length && lastphone.length <= modifiedphone.length) {
          setLastPhone(numericString)
          return `1 (${numericString.slice(0, 3)}) ${format2}-${format3}`;
        }
        else {
          if (modifiedphone && modifiedphone.length && modifiedphone.charAt(modifiedphone.length - 1) == "-" || modifiedphone && modifiedphone.length && modifiedphone.charAt(modifiedphone.length - 1) == " " || modifiedphone && modifiedphone.length && modifiedphone.charAt(modifiedphone.length - 1) == ")" || modifiedphone && modifiedphone.length && modifiedphone.charAt(modifiedphone.length - 1) == "(") {
            setLastPhone(numericString)
            return `${data.slice(0, data.length - 1)}`;
          }
          else {
            setLastPhone(numericString)
            return `1 (${numericString.slice(0, 3)}) ${format2}-${format3}`;
          }
        }
      }
    }
  }

  async function onSubmit(data) {
    // dispatch(alertActions.clear());
    setDisabled(true);
    try {
      // create or update user based on id param
      let message;
      if (orderId) {
        let orderID = order?._id;
        data.shipping_address.phone = modifiedphone;
        await dispatch(orderActions.update({ orderID, data })).unwrap();
        message = "Order has been updated";
        // history.navigate("/");
        let searchObj = {
          search: "",
          status: "",
          date: [],
        };
        dispatch(orderActions.getAll(searchObj)).then((data) => {
          // console.log(data);
          const orderArr = data?.payload?.data?.orders;
          let item = orderArr?.filter((c) => {
            const orderObj = c._id === orderId;
            return orderObj;
          });

          setReceiverInfo({
            receiverName: `${item[0]?.customer?.first_name} ${item[0]?.customer?.last_name}`,
            contactNumber: `${item[0]?.customer?.phone || `N/A`}`,
            addres: `${item[0]?.shipping_address?.address1 ||
              `${item[0]?.shipping_address.city}, ${item[0]?.shipping_address.province}, ${item[0]?.shipping_address.country}, ${item[0]?.shipping_address.zip}` ||
              `N/A`
              }`,
          });
          // reset(item[0]);
          setOrder(item[0]);
          // setLabel("Update");
          // setTitle("Update order");
        });
      } else {
        if (merchant) {
          console.log("createorder1");
          data.merchant = merchant;
          delete data.user;
          console.log("okay ", data);
          await dispatch(orderActions.createSingle({ data })).unwrap();
          message = "Order has been created";
          // history.navigate("/");
        } else {
          console.log("createorder1");
          delete data.user;
          console.log("okay ", data);
          await dispatch(orderActions.createSingle({ data })).unwrap();
          message = "Order has been created";
          // history.navigate("/");
        }
      }

      dispatch(alertActions.success({ message, showAfterRedirect: true }));
      let searchObj = {
        search: "",
        status: "",
        date: [],
      };
      dispatch(orderActions.getAll(searchObj));
    } catch (error) {
      dispatch(alertActions.error(error));
    }
    setTimeout(() => {
      setDisabled(false);
    }, 2500);
  }

  const pushOnfleet = async () => {
    try {
      let message;
      let id = order?._id;
      await dispatch(orderActions.pushOnfleet(id)).unwrap();
      message = "Order has been pushed to onfleet";

      dispatch(alertActions.success({ message, showAfterRedirect: true }));
    } catch (error) {
      dispatch(alertActions.error(error));
    }
  };

  return (
    <div
      className={Style.order_mainContainer}
      style={{ marginLeft: mobile ? mobile.class : "290px" }}
    >
      <div className={Style.main_container_edit}>
        <div className={Style.head}>
          <h1 className={Style.title} style={{ textAlign: "left" }}>
            {title}
          </h1>
          {/* {console.log("order", order)} */}
          <div className={Style.button_wrapper} style={{ gap: "20px" }}>
            {orderId && (
              <button className={Style.download_btn} onClick={downloadQR}>
                <QrCode2Icon /> Generate Label
              </button>
            )}
            {orderId &&
              role === "admin" &&
              order?.onFleetPushedStatus === "requested" && (
                <button className={Style.download_btn} onClick={pushOnfleet}>
                  {/* <AllInclusiveIcon />  */}
                  Push Onfleet
                </button>
              )}
            {orderId &&
              role === "admin" &&
              order?.onFleetPushedStatus !== "requested" && (
                <button
                  className={Style.download_btn}
                  disabled
                  style={{
                    cursor: "not-allowed",
                  }}
                >
                  {/* <AllInclusiveIcon />  */}
                  Push Onfleet
                </button>
              )}
            {orderId && role === "user" && (
              <button
                className={Style.download_btn}
                onClick={async () => {
                  console.log(order);
                  if (order?.shipping_address?.phone) {
                    const res = await dispatch(
                      orderActions.requestToShip({ orderIdsArray: [orderId] })
                    );
                    if (res?.payload?.success) {
                      downloadQR();
                      if (order.onFleetPushedStatus === "requested") {
                        setOrder({
                          ...order,
                          onFleetPushedStatus: "cancelled",
                        });
                      } else if (order.onFleetPushedStatus !== "cancelled") {
                        setOrder({
                          ...order,
                          onFleetPushedStatus: "requested",
                        });
                      }
                    }
                    let message =
                      order.onFleetPushedStatus === "requested"
                        ? "Pickup request cancelled"
                        : "Pickup request sent";
                    dispatch(
                      alertActions.success({ message, showAfterRedirect: true })
                    );
                  } else {
                    let message = "Please add customer phone number";
                    dispatch(
                      alertActions.error({ message, showAfterRedirect: true })
                    );
                  }
                }}
                disabled={order?.onFleetPushedStatus === "pushed"}
                style={{
                  cursor:
                    order?.onFleetPushedStatus === "pushed"
                      ? "not-allowed"
                      : "pointer",
                }}
              >
                {/* <AllInclusiveIcon /> */}{" "}
                {order.onFleetPushedStatus === "requested"
                  ? "Cancel pickup request"
                  : "Request pickup"}
              </button>
            )}
            <Link to={`/`} className={Style.col_btn}>
              <ArrowBackIosNewIcon style={{ height: "15px", width: "20px" }} />{" "}
              <span>Back</span>
            </Link>
          </div>
        </div>
        <div style={{ display: "none" }} ref={qrCodeRef}>
          <QRcode
            id="myqr"
            value={generateQRCodeData()}
            size={320}
            includeMargin={true}
          />
        </div>
        <div className={Style.wrapper}>
          <div className={Style.details}>
            <div className={Style.head_detail}>
              <h1 className={Style.head_title} style={{ textAlign: "left" }}>
                Order Information
              </h1>
              <span className={`${order?.status} status`}>{order?.status}</span>
            </div>
            <div className={Style.detail_wrapper}>
              <div className={Style.detail_row}>
                <div className={Styles.input_wrapper}>
                  <p className={Style.label}>Order ID</p>

                  <input
                    placeholder="Order ID"
                    {...register("orderNo")}
                    className={Style.inputs}
                  />
                  <div className={Styles.invalid_feedback}>
                    {errors.orderNo?.message}
                  </div>
                </div>
                <div className={Styles.input_wrapper}>
                  <p className={Style.label}>Total Weight</p>
                  <input
                    value={order?.totalWeight}
                    placeholder="Total Weight"
                    name="weight"
                    type="text"
                    className={Style.inputs}
                  />
                  <div className={Styles.invalid_feedback}>
                    {errors.phoneNo?.message}
                  </div>
                </div>
                <div className={Styles.input_wrapper}>
                  <p className={Style.label}>Subtotal Price</p>
                  <input
                    placeholder="amount"
                    name="amount"
                    type="text"
                    {...register("amount")}
                    className={Style.inputs}
                  />
                  <div className={Styles.invalid_feedback}>
                    {errors.amount?.message}
                  </div>
                </div>
              </div>
              <div className={Style.detail_row}>
                <div className={Styles.input_wrapper}>
                  <p className={Style.label}>Customer Id</p>
                  <input
                    placeholder="Customer ID"
                    name="customerid"
                    type="text"
                    {...register("customer.id")}
                    className={Style.inputs}
                  />
                  <div className={Styles.invalid_feedback}>
                    {errors.name?.message}
                  </div>
                </div>
                <div className={Styles.input_wrapper}>
                  <p className={Style.label}>First Name</p>
                  <input
                    placeholder="First Name"
                    name="firstName"
                    type="text"
                    {...register("customer.first_name")}
                    className={Style.inputs}
                  />
                  <div className={Styles.invalid_feedback}>
                    {errors.phoneNo?.message}
                  </div>
                </div>
                <div className={Styles.input_wrapper}>
                  <p className={Style.label}>Last Name</p>
                  <input
                    placeholder="Last Name"
                    name="lastName"
                    type="text"
                    {...register("customer.last_name")}
                    className={Style.inputs}
                  />
                  <div className={Styles.invalid_feedback}>
                    {errors.phoneNo?.message}
                  </div>
                </div>
              </div>
              <div className={Style.detail_row}>
                <div className={Styles.input_wrapper}>
                  <p className={Style.label}>Email Address</p>
                  <input
                    placeholder="Customer Email"
                    name="customerEmai"
                    type="text"
                    {...register("customer.email")}
                    className={Style.inputs}
                  />
                  <div className={Styles.invalid_feedback}>
                    {errors.name?.message}
                  </div>
                </div>
                <div className={Styles.input_wrapper}>
                  <p className={Style.label}>Phone No.</p>
                  <input
                    placeholder="1 416-555-4481"
                    name="customerPhone"
                    type="text"
                    value={modifiedphone}
                    onChange={(e) => {
                      var mphon = convertNumber(e.target.value)
                      setmodifiedphone(mphon)
                      setValue("shipping_address.phone", mphon)
                    }}
                    className={Style.inputs}
                  />
                  <div className={Styles.invalid_feedback}>
                    {errors.phoneNo?.message}
                  </div>
                </div>
                <div className={Styles.input_wrapper}>
                  <p className={Style.label}>Package Size</p>
                  <select className={Style.inputs} {...register("packageSize")}>
                    <option value="small">Small</option>
                    <option value="medium">Medium</option>
                    <option value="large">Large</option>
                  </select>
                </div>
              </div>
              <div className={Style.detail_row}>
                <div className={Styles.input_wrapper}>
                  <p className={Style.label}>No. of Boxes</p>
                  <input
                    placeholder="No. Of Boxes in order"
                    name="noOfItems"
                    type="number"
                    {...register("noOfItems")}
                    className={Style.inputs}
                  />
                  <div className={Styles.invalid_feedback}>
                    {errors.name?.message}
                  </div>
                </div>
                <div></div>
                <div></div>
              </div>
            </div>
          </div>
          {/* <div className={Style.details}>
            <div className={Style.head_detail}>
              <h1 className={Style.head_title} style={{ textAlign: "left" }}>
                Billing Address
              </h1>
            </div>
            <div className={Style.detail_wrapper}>
              <div className={Style.detail_row}>
                <div className={Styles.input_wrapper}>
                  <p className={Style.label}>Full Name</p>
                  <input
                    placeholder="Full Name"
                    name="billingfn"
                    type="text"
                    {...register(`billing_address.name`)}
                    className={Style.inputs}
                  />
                </div>
                <div className={Styles.input_wrapper}>
                  <p className={Style.label}>Phone No.</p>
                  <input
                    placeholder="Phone Number"
                    name="billingPhone"
                    type="text"
                    {...register(`billing_address.phone`)}
                    className={Style.inputs}
                  />
                </div>
                <div className={Styles.input_wrapper}></div>
              </div>
              <div className={Style.detail_row}>
                <div className={Styles.input_wrapper}>
                  <p className={Style.label}>Country Code</p>
                  <input
                    placeholder="Country"
                    name="country"
                    type="text"
                    {...register(`billing_address.country`)}
                    className={Style.inputs}
                  />
                </div>
                <div className={Styles.input_wrapper}>
                  <p className={Style.label}>Province Code</p>
                  <input
                    placeholder="Province"
                    name="province"
                    type="text"
                    {...register(`billing_address.province`)}
                    className={Style.inputs}
                  />
                </div>
                <div className={Styles.input_wrapper}>
                  <p className={Style.label}>City</p>
                  <input
                    placeholder="City"
                    name="city"
                    type="text"
                    {...register(`billing_address.city`)}
                    className={Style.inputs}
                  />
                </div>
              </div>
              <div className={Style.detail_row}>
                <div className={Styles.input_wrapper}>
                  <p className={Style.label}>Address</p>
                  <input
                    placeholder="Address"
                    name="address"
                    type="text"
                    {...register(`billing_address.address1`)}
                    className={Style.inputs}
                  />
                </div>
                <div className={Styles.input_wrapper}>
                  <p className={Style.label}>Postal Code</p>
                  <input
                    placeholder="Zip Code"
                    name="zip"
                    type="text"
                    {...register(`billing_address.zip`)}
                    className={Style.inputs}
                  />
                </div>
              </div>
            </div>
          </div> */}

          <div className={Style.details}>
            <div className={Style.head_detail}>
              <h1 className={Style.head_title} style={{ textAlign: "left" }}>
                Shipping Address
              </h1>
            </div>
            <div className={Style.detail_wrapper}>
              <div className={Style.detail_row}>
                <div className={Styles.input_wrapper}>
                  <p className={Style.label}>Full Name</p>
                  <input
                    placeholder="First Name"
                    name="shippingfn"
                    type="text"
                    {...register(`shipping_address.name`)}
                    className={Style.inputs}
                  />
                  <div className={Styles.invalid_feedback}>
                    {errors.phoneNo?.message}
                  </div>
                </div>
                <div className={Styles.input_wrapper}>
                  <p className={Style.label}>Address</p>
                  <input
                    className={Style.inputs}
                    placeholder="Address"
                    name="address"
                    type="text"
                    {...register(`shipping_address.address1`)}
                  />
                </div>
              </div>
              <div className={Style.detail_row}>
                <div className={Styles.input_wrapper}>
                  <p className={Style.label}>Country</p>
                  <input
                    placeholder="Country"
                    name="country"
                    type="text"
                    {...register(`shipping_address.country`)}
                    className={Style.inputs}
                  />
                  <div className={Styles.invalid_feedback}>
                    {errors.phoneNo?.message}
                  </div>
                </div>
                <div className={Styles.input_wrapper}>
                  <p className={Style.label}>City</p>
                  <input
                    placeholder="City"
                    name="city"
                    type="text"
                    {...register(`shipping_address.city`)}
                    className={Style.inputs}
                  />
                  <div className={Styles.invalid_feedback}>
                    {errors.name?.message}
                  </div>
                </div>
                <div className={Styles.input_wrapper}>
                  <p className={Style.label}>Province</p>
                  <input
                    placeholder="Province"
                    name="province"
                    type="text"
                    {...register(`shipping_address.province`)}
                    className={Style.inputs}
                  />
                  <div className={Styles.invalid_feedback}>
                    {errors.phoneNo?.message}
                  </div>
                </div>
              </div>
              <div className={Style.detail_row}>
                <div className={Styles.input_wrapper}>
                  <p className={Style.label}>Postal Code</p>
                  <input
                    placeholder="Zip Code"
                    name="zip"
                    type="text"
                    {...register(`shipping_address.zip`)}
                    className={Style.inputs}
                  />
                  <div className={Styles.invalid_feedback}>
                    {errors.phoneNo?.message}
                  </div>
                </div>
                <div></div>
                <div></div>
              </div>
              <div className={Style.detail_row}>
                <div className={Styles.input_wrapper}>
                  <p className={Style.label}>Special Instructions</p>
                  <textarea
                    className={Style.inputs}
                    {...register(`shipping_address.specialInstructions`)}
                    style={{
                      height: "100px",
                    }}
                  ></textarea>
                </div>
              </div>
            </div>
          </div>
        </div>
        {!(order?.loading || order?.error) && (
          <form onSubmit={handleSubmit(onSubmit)} className={Style.order_form}>
            <div className={Style.input_container}>
              <div className={Styles.invalid_feedback}>
                {errors.name?.message}
              </div>
            </div>

            <div className={Style.button_wrapper} style={{ gap: "20px" }}>
              <button
                type="submit"
                disabled={disabled}
                className={Style.input_btn}
              >
                {isSubmitting && disabled && <span className="spinner"></span>}
                {label}
              </button>
            </div>
          </form>
        )}
        {order?.loading && (
          <div style={{ textAlign: "center", marginTop: "40px" }}>
            <Loader />
          </div>
        )}
        {order?.error && (
          <div class="text-center m-5">
            <div class="text-danger">Error loading user: {orders.error}</div>
          </div>
        )}
      </div>
    </div>
  );
}
