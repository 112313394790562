import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { useSelector, useDispatch } from 'react-redux';
import Style from './style.module.css';
import Styles from "../account/style.module.css";
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { userActions, alertActions } from '_store';
import { Loader } from '../../_components/loader';
// import axios from 'axios';

export { AddEdit };

function AddEdit({ handleClose, id }) {
    // console.log("id", id);
    const [disabled, setDisabled] = useState(false)
    // Add these variables to your component to track the state
    const [showPassword, setShowPassword] = useState(false);
    const handleClickShowPassword = () => setShowPassword(!showPassword);
    const handleMouseDownPassword = () => setShowPassword(!showPassword);

    const [title, setTitle] = useState();
    const [label, setLabel] = useState();
    const [filedDisable, setFiledDisable] = useState()

    const dispatch = useDispatch();
    const user = useSelector(x => x.users.list);
    // console.log("user-->", user);
    let phoneRejex =
        /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
    // form validation rules 
    const validationSchema = Yup.object().shape({
        name: Yup.string()
            .required('First Name is required'),
        businessName: Yup.string()
            .required('Business Name is required'),
        // businessLocation: Yup.string()
        //     .required('Business Location is required'),
        businessAddress: Yup.string()
            .required('Business Address is required'),
        email: Yup.string()
            .required('Email is required').email(),
        phoneNo: Yup.string()
            .required("Phone Number is required")
            .matches(phoneRejex, "Phone Number is not valid"),
        password: Yup.string()
            .transform(x => x === '' ? undefined : x)
            // password optional in edit mode 
            .concat(id ? null : Yup.string().required('Password is required'))
            .min(6, 'Password must be at least 6 characters')
    });
    const formOptions = { resolver: yupResolver(validationSchema) };

    // get functions to build form with useForm() hook
    const { register, handleSubmit, reset, formState } = useForm(formOptions);
    const { errors, isSubmitting } = formState;

    useEffect(() => { 
        if (id) {
            setTitle('Edit Merchant Detail');
            setLabel('UPDATE');
            setFiledDisable("disabled")
            // fetch user details into redux state and 
            // populate form fields with reset()
            // and filter by props id

            dispatch(userActions.getAll())
                .then(user => {
                    const userArr = user?.payload?.data?.users
                    let item = userArr.filter((c) => {
                        const userObj = c._id === id
                        return userObj
                    })
                    // console.log(item, "item");
                    // console.log(user,"USER");
                    const currentUser = {
                        name: item[0].name,
                        phoneNo: item[0].phoneNo,
                        email: item[0].email,
                        password: '',
                        businessName: item[0].businessName,
                        // businessLocation: item[0].businessLocation,
                        businessAddress: item[0].businessAddress,
                    }
                    reset(currentUser)
                })
        } else {
            setTitle('Add New Merchant');
            setLabel('ADD MERCHANT');
        }
    }, []);

    async function onSubmit(data) {
        let obj = data.password ? {
            "password": data.password,
            "phoneNo": Number(data.phoneNo),
            "name": data.name,
            "email": data.email,
            'businessName': data.businessName,
            // 'businessLocation': data.businessLocation,
            'businessAddress': data.businessAddress,
            'businessLogo': data.businessLogo
        } :
            {
                "phoneNo": Number(data.phoneNo),
                "name": data.name,
                "email": data.email,
                'businessName': data.businessName,
                'businessLocation': data.businessLocation,
                'businessAddress': data.businessAddress,
                'businessLogo': data.businessLogo
            }
        const copyData = { ...obj }
        // console.log({ copyData });
        const formData = new FormData();
        Object.keys(copyData).forEach((key) => {
            if (key === "businessLogo") {
                console.log(copyData, "---->");
                formData.append(key, copyData[key][0]);
            } else {
                formData.append(key, copyData[key]);
            }
        })

        dispatch(alertActions.clear());
        setDisabled(true)
        try {
            // create or update user based on id param
            // let message;
            if (id) {
                const updatemerchant = await dispatch(userActions.update({ id, formData })).unwrap();
                console.log("updatemerchant", updatemerchant);
                if (updatemerchant.status === 200) {
                    // redirect to login page and display success alert
                    const message = updatemerchant?.data?.data?.message
                    handleClose(false)
                    dispatch(alertActions.success({ message, showAfterRedirect: true }));
                }
                // message = 'User has been updated';
            } else {
                const addmerchatnt = await dispatch(userActions.addUser({ formData })).unwrap();
                console.log("addmerchatnt", addmerchatnt);
                if (addmerchatnt.status === 200) {
                    // redirect to login page and display success alert
                    console.log('asd',addmerchatnt?.data?.data);
                    const message = addmerchatnt?.data?.data
                    handleClose(false)
                    dispatch(alertActions.success({ message, showAfterRedirect: true }));
                }
            }

            // redirect to user list with success message
            dispatch(userActions.getAll());
        } catch (error) {
            dispatch(alertActions.error(error));
        }
        setTimeout(() => {
            setDisabled(false)
        }, 2500);
    }

    return (
        <div className={Style.sections_edit}>
            <div className={Style.main_container_edit}>
                <h1 className={Style.title}>{title}</h1>
                {!(user?.loading || user?.error) &&
                    <form onSubmit={handleSubmit(onSubmit)} style={{ marginTop: "50px" }}>
                        <div className={Styles.input_row}>
                            <div className={Styles.input_wrapper}>
                                <input placeholder="Full Name" name="name" type="text" {...register('name')} className={`${errors.name ? 'is-invalid' : ''} theme_input`} />
                                <div className={Styles.invalid_feedback}>{errors.name?.message}</div>
                            </div>
                            <div className={Styles.input_wrapper}>
                                <input placeholder='Phone Number' name="phoneNo" type="text" {...register('phoneNo')} className={`${errors.phoneNo ? 'is-invalid' : ''} theme_input`} />
                                <div className={Styles.invalid_feedback}>{errors.phoneNo?.message}</div>
                            </div>
                        </div>
                        {/* <div className={Styles.input_row}> */}
                            <div className={Styles.input_wrapper}>
                                <input placeholder='Business Name*' name="businessName" type="text" {...register('businessName')} className={`${errors.businessName ? 'is-invalid' : ''} theme_input`} />
                                <div className={Styles.invalid_feedback} >{errors.businessName?.message}</div>
                            </div>
                            {/* <div className={Styles.input_wrapper}>
                                <input placeholder='Business Location*' name="businessLocation" type="text" {...register('businessLocation')} className={`${errors.businessLocation ? 'is-invalid' : ''} theme_input`} />
                                <div className={Styles.invalid_feedback} >{errors.businessLocation?.message}</div>
                            </div> */}
                        {/* </div> */}
                        <div className={Styles.input_row}>
                            <div className={Styles.input_wrapper}>
                                <input placeholder='Business Address*' name="businessAddress" type="text" {...register('businessAddress')} className={`${errors.businessAddress ? 'is-invalid' : ''} theme_input`} />
                                <div className={Styles.invalid_feedback} >{errors.businessAddress?.message}</div>
                            </div>
                            <div className={Styles.input_wrapper}>
                                <input type="file" name="businessLogo"  {...register('businessLogo')} className={`${errors.log ? 'is-invalid' : ''} theme_input`} />
                                <div className={Styles.invalid_feedback} >{errors.log?.message}</div>
                            </div>
                        </div>
                        <div className={Styles.input_wrapper}>
                            <input disabled={filedDisable} placeholder='Email Address' name="email" type="text" {...register('email')} className={`${errors.email ? 'is-invalid' : ''} theme_input`} />
                            <div className={Styles.invalid_feedback}>{errors.email?.message}</div>
                        </div>
                        <div className={Styles.input_wrapper}>
                            <label className="form-label">
                                {id && <span className={Style.notification}>(Leave blank to keep the same password)</span>}
                            </label>
                            <div className={Styles.password_field}>
                                <input placeholder='Password' name="password"
                                    type={showPassword ? 'text' : 'password'} {...register('password')}
                                    className={`${errors.password ? 'is-invalid' : ''} theme_input`} />
                                <span className={Styles.pass_icon} aria-label="toggle password visibility"
                                    onClick={handleClickShowPassword}
                                    onMouseDown={handleMouseDownPassword} > {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />} </span>
                            </div>
                            <div className={Styles.invalid_feedback}>{errors.password?.message}</div>

                        </div>

                        <div className={Styles.input_row} style={{ gap: "20px" }}>
                            <button type="submit" disabled={disabled} className={Styles.theme_btn}>
                                {isSubmitting && disabled && <span className="spinner"></span>}
                                {label}
                            </button>
                        </div>
                    </form>
                }
                {user?.loading &&
                    <div style={{ textAlign: "center", marginTop: "40px" }}>
                        <Loader />
                    </div>
                }
                {user?.error &&
                    <div class="text-center m-5">
                        <div class="text-danger">Error loading user: {user.error}</div>
                    </div>
                }
            </div>
        </div>
    );
}
