import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { fetchWrapper } from '_helpers';
import { alertActions } from '_store';
// create slice

const name = 'invoice';
const initialState = createInitialState();
const extraActions = createExtraActions();
const extraReducers = createExtraReducers();
const slice = createSlice({ name, initialState, extraReducers });

// exports

export const invoiceActions = { ...slice.actions, ...extraActions };
export const invoiceReducer = slice.reducer;

// implementation

function createInitialState() {
    return {
        list: null,
        item: null
    }
}

function createExtraActions() {

    return {
        addInvoice: addInvoice(),
        getAll: getAll(),
        update: update(),
    };
    function addInvoice() {
        return createAsyncThunk(
            `${name}/addInvoice`,
            async ({ formData }, { dispatch }, _) => {
                const auth = JSON.parse(localStorage.getItem("auth"))
                const token = auth?.data?.token
                return axios.post(`${process.env.REACT_APP_BACKEND_URL}/invoice`, formData, {
                    headers: {
                        Accept: "application/json",
                        Authorization: `${token}`,
                        "Content-Type": "multipart/form-data",
                    }
                })
                    .then(response => {
                        // console.log(response);
                        return response
                    })
                    .catch(error => {
                        // console.log(error);
                        let message = error.response.data.message
                        dispatch(alertActions.error(message));
                        return error
                    });
            }
        );
    }

    function getAll() {
        return createAsyncThunk(
            `${name}/getAll`,
            async (data) => {
                return await fetchWrapper.get(`${process.env.REACT_APP_BACKEND_URL}/invoice`, data)
            }
        );
    }

    function update() {
        return createAsyncThunk(
            `${name}/update`,
            async function ({ invoiceId, obj }) {
                let response = await fetchWrapper.put(`${process.env.REACT_APP_BACKEND_URL}/invoice/${invoiceId}`, obj);
                return response
            }
        );
    }

}

function createExtraReducers() {
    return (builder) => {
        getAll();

        function getAll() {
            var { pending, fulfilled, rejected } = extraActions.getAll;
            builder
                .addCase(pending, (state) => {
                    state.list = { loading: true };
                })
                .addCase(fulfilled, (state, action) => {
                    state.list = { value: action.payload };
                })
                .addCase(rejected, (state, action) => {
                    state.list = { error: action.error };
                });
        }

    }
}
