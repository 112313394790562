import { Routes, Route, Navigate } from 'react-router-dom';

import { Invoice, AddEditInvoice } from '.';

export { InvoiceLayout };

function InvoiceLayout() {
    return (
        <Routes>
            <Route index element={<Invoice />} />
            <Route path="edit/:id" element={<AddEditInvoice />} />
            <Route path="edit/" element={<AddEditInvoice />} />
            <Route path="*" element={<Navigate to="/" />} />
        </Routes>
    );
}
