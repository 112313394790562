import React, { useEffect, useState } from "react";
import Style from "./style.module.css";
import { useSelector } from "react-redux";
import Styles from "../account/style.module.css";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { Button } from "@mui/material";
import { styled } from "@mui/material/styles";
import { authActions, userActions } from "_store";
import { useDispatch } from "react-redux";
import { locationActions } from "_store";

const UpdateProfile = () => {
  const dispatch = useDispatch();
  const mobile = useSelector((x) => x.mobile.value);
  const validationSchema = Yup.object().shape({
    name: Yup.string().required("name is required"),
    phoneNo: Yup.string().required("phone number is required"),
    businessName: Yup.string().required("business name is required"),
    businessLocation: Yup.string().required("business location is required"),
    businessAddress: Yup.string().required("business address is required"),
    email: Yup.string().email().required("email is required"),
  });
  const formOptions = { resolver: yupResolver(validationSchema) };
  const { register, handleSubmit, reset, formState } = useForm(formOptions);
  const { errors, isSubmitting } = formState;

  const [bLocation, setBLocation] = useState("");
  async function onSubmit(data) {
    const formData = new FormData();
    formData.append("name", data.name);
    formData.append("phoneNo", data.phoneNo);
    formData.append("businessName", data.businessName);
    formData.append("businessLocation", data.businessLocation);
    formData.append("businessAddress", data.businessAddress);
    formData.append("email", data.email);
    if (typeof data.businessLogo === "object") {
      formData.append("businessLogo", data.businessLogo[0]);
    }

    const res = await dispatch(userActions.update({ id: data._id, formData }));
    if (res?.payload?.status === 200) {
      let auth = JSON.parse(localStorage.getItem("auth"));
      auth.data.user = res.payload.data.data.updatedUser;
      console.log("auth", auth);
      localStorage.setItem("auth", JSON.stringify(auth));
      reset(res.payload.data.data.updatedUser);
      setLogo(res.payload.data.data.updatedUser.businessLogo);
      dispatch(authActions.setAuth(auth));
    }
  }

  const [logo, setLogo] = useState(null);

  useEffect(() => {
    const auth = JSON.parse(localStorage.getItem("auth"));
    reset(auth?.data?.user);
    setBLocation(auth?.data?.user?.businessLocation);
    setLogo(auth?.data?.user?.businessLogo);
  }, []);

  useEffect(() => {
    dispatch(locationActions.getAll());
  }, []);

  const location = useSelector((x) => x.location.list);

  const VisuallyHiddenInput = styled("input")({
    clip: "rect(0 0 0 0)",
    clipPath: "inset(50%)",
    height: 1,
    overflow: "hidden",
    position: "absolute",
    bottom: 0,
    left: 0,
    whiteSpace: "nowrap",
    width: 1,
  });

  return (
    <div
      className={Style.order_mainContainer}
      style={{ marginLeft: mobile ? mobile.class : "290px" }}
    >
      {console.log("location", bLocation)}
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className={Style.main_container_edit}>
          <div className={Style.head}>
            <h1 className={Style.head_title} style={{ textAlign: "left" }}>
              Profile
            </h1>
          </div>
          <div className={Style.wrapper}>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <div
                style={{
                  width: "28%",
                  // backgroundColor: "red",
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "column",
                }}
                className={Style.detail_wrapper}
              >
                <img
                  src={
                    logo
                      ? // ? `${process.env.BACKEND_URL}${logo}`
                        "https://iship.herokuapp.com" + logo
                      : "/images/profile.jpg"
                  }
                  alt="profile"
                  style={{
                    width: "150px",
                    height: "150px",
                    objectFit: "cover",
                    borderRadius: "50%",
                  }}
                />
                <div
                  className={Style.button_wrapper}
                  style={{
                    marginTop: "20px",
                  }}
                >
                  <Button
                    component="label"
                    variant="contained"
                    style={{
                      height: "40px",
                      fontSize: "16px",
                      lineHeight: "16px",
                      backgroundColor: "var(--primaryColor)",
                      color: "var(--textLight)",
                      fontFamily: "AxiformaRegular",
                      width: "100%",
                      border: "none",
                      borderRadius: "5px",
                      cursor: "pointer",
                      position: "relative",
                      padding: "0 20px",
                    }}
                  >
                    + Upload Business Logo
                    <VisuallyHiddenInput
                      type="file"
                      name="businessLogo"
                      {...register("businessLogo")}
                    />
                  </Button>
                </div>
                <span
                  style={{
                    fontSize: "15px",
                    color: "gray",
                    marginTop: "10px",
                  }}
                >
                  (Max limit 5mb per image)
                </span>
              </div>
              <div
                style={{
                  width: "70%",
                }}
              >
                <div className={Style.detail_wrapper}>
                  <div className={Style.detail_row}>
                    <div className={Styles.input_wrapper}>
                      <p className={Style.label}>Full Name</p>
                      <input
                        placeholder="Full Name"
                        name="name"
                        type="text"
                        {...register("name")}
                        className={Style.inputs}
                      />
                      <div className={Styles.invalid_feedback}>
                        {errors.name?.message}
                      </div>
                    </div>
                    <div className={Styles.input_wrapper}>
                      <p className={Style.label}>Phone No.</p>
                      <input
                        placeholder="Phone No."
                        name="phoneNumber"
                        type="text"
                        {...register("phoneNo")}
                        className={Style.inputs}
                      />
                      <div className={Styles.invalid_feedback}>
                        {errors.phoneNumber?.message}
                      </div>
                    </div>
                  </div>
                  <div className={Style.detail_row}>
                    <div className={Styles.input_wrapper}>
                      <p className={Style.label}>Email Address</p>
                      <input
                        placeholder="Email Address"
                        disabled
                        name="email"
                        type="text"
                        {...register("email")}
                        className={Style.inputs}
                        style={{
                          cursor: "not-allowed",
                        }}
                      />
                      <div className={Styles.invalid_feedback}>
                        {errors.email?.message}
                      </div>
                    </div>
                    <div className={Styles.input_wrapper}></div>
                  </div>
                  <div className={Style.detail_row}>
                    <div className={Styles.input_wrapper}>
                      <p className={Style.label}>Business Name</p>
                      <input
                        placeholder="Business Name"
                        name="businessName"
                        type="text"
                        {...register("businessName")}
                        className={Style.inputs}
                      />
                      <div className={Styles.invalid_feedback}>
                        {errors.businessName?.message}
                      </div>
                    </div>
                    <div className={Styles.input_wrapper}>
                      <p className={Style.label}>Head Office/Main Location</p>
                      {/* <input
                        placeholder="Business Location"
                        name="businessLocation"
                        type="text"
                        {...register("businessLocation")}
                        className={Style.inputs}
                      /> */}
                      <select
                        className={Style.inputs}
                        {...register(`businessLocation`)}
                        name="businessLocation"
                        value={bLocation}
                        onChange={(e) => setBLocation(e.target.value)}
                      >
                        {location?.value?.data?.locations?.map((x, i) => (
                          <option key={i} value={x.completeAddress}>
                            {x.completeAddress}
                          </option>
                        ))}
                      </select>
                      <div className={Styles.invalid_feedback}>
                        {errors.businessLocation?.message}
                      </div>
                    </div>
                  </div>
                  <div className={Style.detail_row}>
                    <div className={Styles.input_wrapper}>
                      <p className={Style.label}>Business Address</p>

                      <textarea
                        className={Style.inputs}
                        {...register(`businessAddress`)}
                        style={{
                          height: "100px",
                          padding: "10px",
                        }}
                        placeholder="Business Address"
                        name="businessAddress"
                      ></textarea>
                      <div className={Styles.invalid_feedback}>
                        {errors.businessAddress?.message}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            marginTop: "20px",
          }}
        >
          {console.log("isSubmitting", isSubmitting)}
          {isSubmitting ? (
            <button
              className={Style.input_btn}
              disabled
              style={{
                height: "40px",
                fontSize: "16px",
                lineHeight: "16px",
                // backgroundColor: "var(--primaryColor)",
                color: "var(--textLight)",
                fontFamily: "AxiformaRegular",
                border: "none",
                borderRadius: "5px",
                cursor: "pointer",
                position: "relative",
                padding: "0 20px",
                width: "max-content",
              }}
            >
              Update Profile
            </button>
          ) : (
            <button
              className={Style.input_btn}
              style={{
                height: "40px",
                fontSize: "16px",
                lineHeight: "16px",
                backgroundColor: "var(--primaryColor)",
                color: "var(--textLight)",
                fontFamily: "AxiformaRegular",
                border: "none",
                borderRadius: "5px",
                cursor: "pointer",
                position: "relative",
                padding: "0 20px",
                width: "max-content",
              }}
            >
              Update Profile
            </button>
          )}
        </div>
      </form>
    </div>
  );
};

export default UpdateProfile;
