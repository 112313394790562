import React from 'react'

export function Logout(props) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="18.724" height="17.62" viewBox="0 0 18.724 17.62">
            <path id="Path_7604" data-name="Path 7604" d="M7.3,6.2A1.1,1.1,0,0,0,7.3,4H5.1A1.1,1.1,0,0,0,4,5.1V20.519a1.1,1.1,0,0,0,1.1,1.1H7.3a1.1,1.1,0,0,0,0-2.2H6.2V6.2Zm15.219,5.969L19.418,7.766a1.1,1.1,0,1,0-1.8,1.277l1.894,2.665H10.608a1.1,1.1,0,0,0,0,2.2h8.81l-1.982,2.643A1.1,1.1,0,1,0,19.2,17.876l3.3-4.405a1.1,1.1,0,0,0,.022-1.3Z" transform="translate(-4 -4)" />
        </svg>

    )
}

export function Merchants() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="19.013" height="18.123" viewBox="0 0 19.013 18.123">
            <g id="Group_2120" data-name="Group 2120" transform="translate(72.011 -318)">
                <path id="Ellipse_851" data-name="Ellipse 851" d="M5.551-1A6.551,6.551,0,1,1-1,5.551,6.558,6.558,0,0,1,5.551-1Zm0,11.3A4.75,4.75,0,1,0,.8,5.551,4.755,4.755,0,0,0,5.551,10.3Z" transform="translate(-68.056 319)" />
                <path id="Path_7606" data-name="Path 7606" d="M48.1,165.769a.9.9,0,0,1-.781-.45,9.038,9.038,0,0,0-15.652,0,.9.9,0,0,1-1.56-.9A10.839,10.839,0,0,1,39.5,159h0a10.839,10.839,0,0,1,9.386,5.417.9.9,0,0,1-.779,1.351Z" transform="translate(-102 170.355)" />
            </g>
        </svg>
    )
}

export function Orders() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="23.209" height="20.022" viewBox="0 0 23.209 20.022">
            <g id="Octicons" transform="translate(-1 -1.537)">
                <g id="package" transform="translate(1 1.537)">
                    <path id="Shape" d="M1,5.765V17.323a1.565,1.565,0,0,0,1.16,1.5L12.218,21.5a1.318,1.318,0,0,0,.774,0l10.057-2.677a1.565,1.565,0,0,0,1.16-1.5V5.765a1.565,1.565,0,0,0-1.16-1.5L12.991,1.572a2.166,2.166,0,0,0-.774,0L2.16,4.265A1.565,1.565,0,0,0,1,5.765ZM11.831,19.83,2.547,17.37V6.895l9.284,2.491ZM2.547,5.348,6.415,4.311,16.473,6.988,12.6,8.024ZM22.662,17.37l-9.284,2.46V9.386l3.095-.851V12.31l3.095-.82V7.715l3.095-.82Zm-3.095-11.2L9.51,3.491l3.095-.82L22.662,5.348l-3.095.82Z" transform="translate(-1 -1.537)" fillRule="evenodd" />
                </g>
            </g>
        </svg>
    )
}
export function File() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="14.5" height="17.5" viewBox="0 0 14.5 17.5">
            <path id="Subtraction_3" data-name="Subtraction 3" d="M-4824.689-9245h-4.395a2.445,2.445,0,0,1-2.417-2.468v-12.563a2.446,2.446,0,0,1,2.417-2.469h5.292l.288.1.043-.007a1.069,1.069,0,0,1,.162.111l5.261,5.374a1.012,1.012,0,0,1,.108.161v.064l.012.032a1.245,1.245,0,0,1,.041.187v2.73a.669.669,0,0,1-.661.675.667.667,0,0,1-.658-.675v-2.018h-2.857a2.444,2.444,0,0,1-2.415-2.468v-2.916h-4.615a1.11,1.11,0,0,0-1.1,1.12v12.563a1.111,1.111,0,0,0,1.1,1.122h4.395a.669.669,0,0,1,.66.675A.667.667,0,0,1-4824.689-9245Zm1.538-15.205h0v1.97a1.11,1.11,0,0,0,1.1,1.12h1.928Zm1.1,13.418a.661.661,0,0,1-.468-.2l-2.635-2.691a.632.632,0,0,1-.135-.217l0-.006a.687.687,0,0,1,0-.511.817.817,0,0,1,.144-.229l2.631-2.688a.652.652,0,0,1,.468-.2.656.656,0,0,1,.47.2.689.689,0,0,1,0,.956l-1.52,1.542h5.444a.666.666,0,0,1,.66.673.667.667,0,0,1-.66.674h-5.444l1.52,1.542a.677.677,0,0,1,.2.476.69.69,0,0,1-.2.479A.663.663,0,0,1-4822.054-9246.788Z" transform="translate(4831.5 9262.501)" fill="#303030" />
        </svg>

    )
}
export function Search() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="18.766" height="18.766" viewBox="0 0 18.766 18.766">
        <g id="Layer_2" data-name="Layer 2" transform="translate(-3 -3)">
          <path id="search" d="M21.457,19.977l-3.543-3.533a8.254,8.254,0,0,0,1.761-5.107,8.338,8.338,0,1,0-8.338,8.338,8.254,8.254,0,0,0,5.107-1.761l3.533,3.543a1.046,1.046,0,1,0,1.48-1.48ZM5.084,11.338a6.253,6.253,0,1,1,6.253,6.253,6.253,6.253,0,0,1-6.253-6.253Z" transform="translate(0)" fill="#aaa"/>
        </g>
      </svg>
    )
}