import { store, authActions } from '_store';

export const fetchWrapper = {
    get: request('GET'),
    post: request('POST'),
    put: request('PUT'),
    patch: request('PATCH'),
    delete: request('DELETE')
};

function request(method) {
    return (url, body, type) => {
        const requestOptions = {
            method,
            headers: authHeader(url, type),
        };
        if (body) {
            // console.log('type', type);
            // type === 'formData' ? requestOptions.headers['Content-Type'] = "multipart/form-data" : requestOptions.headers['Content-Type'] = 'application/json'
            requestOptions.body = type === 'formData' ? body : JSON.stringify(body);
            // console.log('type', requestOptions);
        }
        // if (token) {
        //     requestOptions.headers['Authorization'] = token;
        // }
        // console.log("requestOptions", requestOptions);
        return fetch(url, requestOptions).then(handleResponse)
        // .catch(e => {
        //     console.log(" = =  = = =  = = ===>", e);
        // });
    }
}

// helper functions

function authHeader(url, type) {
    // console.log(url);
    // return auth header with jwt if user is logged in and request is to the api url
    const token = authToken();
    const isLoggedIn = !!token;
    // const isApiUrl = url.startsWith(process.env.FRONTEND_URL_PORT);
    const isApiUrl = url.startsWith(`${process.env.REACT_APP_BACKEND_URL}`);
    if (isLoggedIn && isApiUrl) {
        // return { Authorization: `Bearer ${token}` };
        return {
            Accept: "application/json",
            Authorization: `${token}`,
            "Content-Type": type === "formData" ? "multipart/form-data" : "application/json",
            mode: 'no-cors'
        };
    } else {
        return {"Content-Type": "application/json"};
    }
}

function authToken() {
    const auth = JSON.parse(localStorage.getItem("auth"))
    const token = auth?.data?.token
    // console.log("here", token);
    // return store.getState().auth?.token;
    return token;
}

async function handleResponse(response) {
    // console.log("inside response");
    const isJson = response.headers?.get('content-type')?.includes('application/json');
    const data = isJson ? await response.json() : null;
    // console.log("response", response);

    // check for error response
    if (!response.ok) {
        if ([401, 403].includes(response.status) && authToken()) {
            // auto logout if 401 Unauthorized or 403 Forbidden response returned from api
            const logout = () => store.dispatch(authActions.logout());
            logout();
        }

        // get error message from body or default to response status
        // console.log("response", data);
        const error = (data && data.message) || response.status;
        return Promise.reject(error);
    }
    // console.log("data--a", data);
    return data;
}