import Style from "./style.module.css";
import * as React from "react";
import { useSelector, useDispatch } from "react-redux";
import { useState, useEffect } from "react";
import { File } from "_assets/icons/icon";
import AddIcon from "@mui/icons-material/Add";
import Box from "@mui/material/Box";
import { DataGrid } from "@mui/x-data-grid";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import Backdrop from "@mui/material/Backdrop";
import Modal from "@mui/material/Modal";
import CloseIcon from "@mui/icons-material/Close";
import { orderActions, alertActions } from "_store";
import { useSpring, animated } from "@react-spring/web";
import { Loader } from "../../_components/loader";
// import AllInclusiveIcon from "@mui/icons-material/AllInclusive";
import DeleteIcon from "@mui/icons-material/Delete";
import { ShopifyImport } from "./ShopifyImport";
import Papa from "papaparse";
import moment from "moment";
// import { Input } from "@mui/material";
// import SearchIcon from "@mui/icons-material/Search";
import QRCode from "qrcode.react";
import { jsPDF } from "jspdf";

export { Orders };

//<Model Styling>
const Fade = React.forwardRef(function Fade(props, ref) {
  const {
    children,
    in: open,
    onClick,
    onEnter,
    onExited,
    ownerState,
    ...other
  } = props;
  const style = useSpring({
    from: { opacity: 0 },
    to: { opacity: open ? 1 : 0 },
    onStart: () => {
      if (open && onEnter) {
        onEnter(null, true);
      }
    },
    onRest: () => {
      if (!open && onExited) {
        onExited(null, true);
      }
    },
  });

  return (
    <animated.div ref={ref} style={style} {...other}>
      {React.cloneElement(children, { onClick })}
    </animated.div>
  );
});

Fade.propTypes = {
  children: PropTypes.element.isRequired,
  in: PropTypes.bool,
  onClick: PropTypes.any,
  onEnter: PropTypes.func,
  onExited: PropTypes.func,
  ownerState: PropTypes.any,
};
//<Model Styling/>

function Orders() {
  const dispatch = useDispatch();
  const mobile = useSelector((x) => x.mobile.value);
  const [rows, setRow] = useState([]);
  const [open, setOpen] = useState(false);
  const [fileName, setFileName] = useState("");

  const [search, setSearch] = useState("");
  const [status, setStatus] = useState("");
  const [pushStatus, setPushStatus] = useState("");

  const [startdate, setstartDate] = useState("");
  const [enddate, setendDate] = useState("");
  const [selectedIds, setSelectedIds] = React.useState([]);
  const [dropDownHandle, setDropDownHandle] = useState(false);
  const [onfleetButton, setOnfleetButton] = useState(false);
  const [trigger, setTrigger] = useState(false);

  const [role, setRole] = useState("");
  const [sync, setSycn] = useState(false);

  //MODEL HANDLE
  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  //MODEL HANDLE

  const userOrders = useSelector((x) => x.order.list);

  useEffect(() => {
    setRole(localStorage.getItem("role"));
  }, [userOrders]);

  useEffect(() => {
    if(localStorage.getItem("import")){
      setSycn(true);
    }else{
      setSycn(false);
    }
    
  }, [sync]);

  useEffect(() => {
    let searchObj = {
      search: search,
      status: status,
      onFleetPushedStatus: pushStatus,
      date: startdate && enddate ? [moment(startdate), moment(enddate)] : [],
    };
    dispatch(orderActions.getAll(searchObj)).unwrap();
    // console.log("searchObj", searchObj);
  }, [search, status, startdate, enddate, pushStatus, trigger]);

  useEffect(() => {
    const render = userOrders?.value?.data;
    if (render?.orders?.length > 0) {
      const allOrder = render?.orders?.map((x) => {
        return {
          id: x._id,
          status: x.status,
          amount: `$${String(x.amount)}`,
          orderID: x.orderNo,
          customer: x.shipping_address?.name ? x.shipping_address?.name : "---",
          noOfItems: `${String(x.noOfItems)} boxes`,
          onFleetPushStatus: x.onFleetPushedStatus
            ? x.onFleetPushedStatus
            : "pending",
          date: moment(x.shopifyOrderDate).format("YYYY-MM-DD HH:mm"),
        };
      });
      setRow(allOrder);
    } else {
      setRow([]);
    }
  }, [userOrders, search, status, startdate, enddate, pushStatus]);
  useEffect(() => {
    if (
      (search && search !== "") ||
      (status && status !== "") ||
      (pushStatus && pushStatus !== "") ||
      (startdate && startdate !== "") ||
      (enddate && enddate !== "")
    ) {
      const filter = {
        search: search,
        status: status,
        onFleetPushedStatus: pushStatus,
        startdate: startdate,
        enddate: enddate,
      };
      localStorage.setItem("filter", JSON.stringify(filter));
    }
  }, [search, status, startdate, enddate, pushStatus]);

  useEffect(() => {
    const filter = JSON.parse(localStorage.getItem("filter"));
    if (filter) {
      // console.log("filter", filter);
      setSearch(filter.search);
      setStatus(filter.status);
      setstartDate(filter.startdate);
      setendDate(filter.enddate);
      setPushStatus(filter.onFleetPushedStatus);
    }
  }, []);
  const changeHandler = async (event) => {
    // Passing file data (event.target.files[0]) to parse using Papa.parse
    setFileName(event.target.files[0].name);
    const formData = new FormData();
    formData.append("csv", event.target.files[0]);
    Papa.parse(event.target.files[0], {
      // header: true,
      // skipEmptyLines: true,
      // complete: async function (results) {
      //     // console.log('csv', results.data)
      //     // const data = results.data;
      //     // const orderArr = data?.map((item, i) => {
      //     //     const orderItem = {
      //     //         "orderNo": item.name,
      //     //         "status": item.financial_status,
      //     //         "store": "ABC",
      //     //         "amount": item.current_total_price,
      //     //         "shopifyOrderDate": item.created_at
      //     //     }
      //     //     return orderItem
      //     // })
      //     // console.log("Filtered obj", orderArr);
      //     // try {
      //     //     // import order from csv file
      //     //     let message;
      //     //     const orderArray = orderArr
      //     //     let orderFetch = await dispatch(orderActions.importOrder(orderArray)).unwrap();
      //     //     console.log(orderFetch);
      //     //     message = 'Order has been import successfully';
      //     //     dispatch(alertActions.success({ message, showAfterRedirect: true }));
      //     //     let searchObj =
      //     //     {
      //     //         "search": search,
      //     //         "status": status,
      //     //         "date": startdate && enddate ? [moment(startdate), moment(enddate)] : []
      //     //     }
      //     //     dispatch(orderActions.getAll(searchObj));
      //     // } catch (error) {
      //     //     dispatch(alertActions.error(error));
      //     // }
      // },
    });

    try {
      // import order from csv file
      // let message;
      // const orderArray = orderArr
      let orderFetch = await dispatch(
        orderActions.importOrder(formData)
      ).unwrap();
      if (orderFetch.status === 200) {
        // redirect to login page and display success alert
        const message = orderFetch?.data?.data;
        handleClose(false);
        dispatch(alertActions.success({ message, showAfterRedirect: true }));
      }
      // message = 'Order has been import successfully';
      // dispatch(alertActions.success({ message, showAfterRedirect: true }));

      let searchObj = {
        search: search,
        status: status,
        date: startdate && enddate ? [moment(startdate), moment(enddate)] : [],
      };
      dispatch(orderActions.getAll(searchObj));
    } catch (error) {
      dispatch(alertActions.error(error));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    // console.log(filter);
    setSearch("");
    setStatus("");
    setendDate("");
    setstartDate("");
    setPushStatus("");
    localStorage.removeItem("filter");
  };

  // // Grid Style
  const grigStyle = {
    ".MuiDataGrid-overlayWrapperInner": {
      maxHeight: "500px",
      minHeight: "500px",
    },
    ".MuiCheckbox-root": {
      padding: "0px",
    },
    ".MuiDataGrid-overlayWrapper": {
      height: "100%!important",
    },
    ".MuiDataGrid-columnHeaders": {
      background: "#f7f7f7",
      minHeight: "80px!important",
    },
    ".MuiDataGrid-cell, .MuiDataGrid-columnHeader": {
      padding: "0 0 0 25px",
    },
    ".MuiDataGrid-cellContent": {
      fontFamily: "AxiformaRegular",
      fontSize: "14px",
      color: "#303030",
    },
    ".MuiDataGrid-columnHeaderTitleContainerContent": {
      fontFamily: "AxiformaSemiBold",
      color: "#000",
      fontSize: "14px",
    },
  };
  // //GRID COLUMN
  const columns = [
    {
      field: "id",
      headerName: "ID",
      minWidth: 100,
      flex: 1,
    },
    {
      field: "orderID",
      headerName: "Order No.",
      minWidth: 80,
      maxWidth: 100,
      flex: 1,
      filterable: true,
    },
    {
      field: "status",
      headerName: "Status",
      minWidth: 120,
      renderCell: (params) => (
        <span className={`${params.value.replaceAll(" ", "")} status`}>
          {params.value}
        </span>
      ),
      flex: 1,
      filterable: true,
    },
    {
      field: "amount",
      headerName: "Amount",
      minWidth: 80,
      flex: 1,
      filterable: true,
    },
    {
      field: "customer",
      headerName: "Customer",
      minWidth: 180,
      flex: 1,
      filterable: true,
    },
    {
      field: "onFleetPushStatus",
      headerName: "Pickup Request",
      minWidth: 150,
      flex: 1,
      filterable: true,
    },
    {
      field: "noOfItems",
      headerName: "No. of Boxes",
      minWidth: 150,
      flex: 1,
      filterable: true,
      renderCell: (cellValues) => {
        return (
          <BoxesComponent
            cellValues={cellValues}
            trigger={trigger}
            setTrigger={setTrigger}
          />
        );
      },
    },
    {
      field: "date",
      headerName: "Tracking",
      minWidth: 150,
      flex: 1,
      renderCell: (cellValues) => {
        return (
          <Link
            to={`https://iships.ca?orderId=${cellValues.row.id}`}
            className={Style.col_btn}
            target="_blank"
          >
            Track
          </Link>
        );
      },
    },
    {
      field: "action",
      headerName: "Action",
      renderCell: (cellValues) => {
        // return <Link onClick={() => { setEditOrder(cellValues.row); handleOpen() }} className={Style.col_btn}>Edit</Link>;
        return (
          <Link to={`/edit/${cellValues.row.id}`} className={Style.col_btn}>
            Edit
          </Link>
        );
      },
      minWidth: 150,
      filterable: false,
      flex: 1,
    },
  ];

  function NoRowsOverlay() {
    return (
      <div className={Style.container_Notfound}>
        <div className={Style.notFound}></div>
      </div>
    );
  }

  function NoResultsOverlay() {
    return (
      <div className={Style.container_Notfound}>
        <div className={Style.notFound}></div>
      </div>
    );
  }

  const pushOnfleet = async () => {
    let obj = {
      arrayOfOrderIds: selectedIds,
    };
    try {
      let message;
      await dispatch(orderActions.pushOnfleetMultiple(obj)).unwrap();
      message = "Order has been pushed to onfleet";

      dispatch(alertActions.success({ message, showAfterRedirect: true }));
    } catch (error) {
      dispatch(alertActions.error(error));
    }
  };
  const deleteOrders = async () => {
    let obj = {
      orderIds: selectedIds,
    };
    // console.log("selectedIds", obj);
    // dispatch(orderActions.getAll()).unwrap()
    try {
      let message;
      await dispatch(orderActions.deleteorder(obj)).unwrap();
      message = "Orders deleted successfully";
      dispatch(alertActions.success({ message, showAfterRedirect: true }));
      setSelectedIds([]);
      let searchObj = {
        search: search,
        status: status,
        date: startdate && enddate ? [moment(startdate), moment(enddate)] : [],
      };
      dispatch(orderActions.getAll(searchObj)).unwrap();
    } catch (error) {
      dispatch(alertActions.error(error));
    }
  };
  const [massRequestButton, setMassRequestButton] = useState(false);
  useEffect(() => {
    if (selectedIds.length > 0) {
      const selectedOrders1 = rows.filter((x) => selectedIds.includes(x.id));
      const index = selectedOrders1.findIndex(
        (x) => x.onFleetPushStatus !== "requested"
      );

      // if any selected order's onfleetpushstatus is not pending then set mass button false else true
      const index2 = selectedOrders1.findIndex(
        (x) => x.onFleetPushStatus !== "pending"
      );
      if (index2 !== -1) {
        setMassRequestButton(false);
      } else {
        setMassRequestButton(true);
      }

      if (index !== -1) {
        setOnfleetButton(true);
      } else {
        setOnfleetButton(false);
      }

      const selectedOrders2 = userOrders?.value?.data?.orders?.filter((x) =>
        selectedIds.includes(x._id)
      );
      setSelectedOrders(selectedOrders2);
    } else {
      setOnfleetButton(false);
      setMassRequestButton(false);
    }
  }, [selectedIds]);

  const [selectedOrders, setSelectedOrders] = useState([]);

  const generateLabels = async () => {
    // console.log("selectedOrders", selectedOrders);
    selectedOrders.map((val, ind) => {
      // const canvas = document.getElementById("myqr" + ind);

      const pdfWidth = 4;
      const pdfHeight = 6;
      const pdf = new jsPDF({
        unit: "in",
        format: [pdfWidth, pdfHeight],
      });

      // const imgData = canvas.toDataURL("image/png");
      // const imgWidth = pdfWidth - 0.7;
      // const imgHeight = (canvas.height * imgWidth) / canvas.width;

      // // Set the desired height for the PDF page

      // // Calculate the coordinates to center the image on the reduced-height page
      // const x = 0.25;
      // const y = 1.2;

      // // Add the image to the PDF
      // pdf.addImage(imgData, "PNG", x, y, imgWidth, imgHeight, "left");

      // // Add some data to the PDF i.e receiver name, contact number and address

      // const from = val?.user?.businessLocation || val?.user?.businessAddress;
      // pdf.setFontSize(12);
      // pdf.text(`FROM: `, x, 0.3);
      // pdf.setFontSize(10);
      // const fromText = pdf.splitTextToSize(`${from}`, imgWidth);
      // pdf.text(`${val?.user?.businessName}`, x, 0.45);
      // pdf.text(`${val?.user?.phoneNo}`, x, 0.62);
      // pdf.text(fromText, x, 0.8, null, null, "left");
      // pdf.setFontSize(12);
      // pdf.text(`TO:`, x, y + imgHeight + 0.2);
      // pdf.setFontSize(10);
      // pdf.text(`${val.shipping_address.name}`, x, y + imgHeight + 0.4);
      // const toText = pdf.splitTextToSize(
      //   `${
      //     val.shipping_address.address1 ||
      //     `${val.shipping_address.city}, ${val.shipping_address.province}, ${val.shipping_address.country}, ${val.shipping_address.zip}`
      //   }`,
      //   imgWidth
      // );
      // pdf.text(toText, x, y + imgHeight + 0.55);

      // // Save the PDF
      // pdf.save(`Label_${val.orderNo}.pdf`);

      // return null;

      const from = val?.user?.businessLocation || val?.user?.businessAddress;
      pdf.setFontSize(10);
      const fromText = pdf.splitTextToSize(`${from}`, 1.5);
      pdf.text(`${val?.user?.businessName}`, 0.25, 0.45);
      pdf.text(`${val?.user?.phoneNo}`, 0.25, 0.62);
      pdf.text(fromText, 0.25, 0.8, null, null, "left");
      pdf.setFontSize(16);
      pdf.text(`${val.shipping_address.name}`, 1, 2);
      const toText = pdf.splitTextToSize(
        `${val.shipping_address.address1 ||
        `${val.shipping_address.city}, ${val.shipping_address.province}, ${val.shipping_address.country}, ${val.shipping_address.zip}`
        }`,
        1.5
      );
      pdf.text(toText, 1, 2.3);

      pdf.setFontSize(16);
      pdf.setFont("courier", "bold");
      pdf.text(`${val.orderNo}`, 3, 0.5);

      pdf.save(`Label_${val.orderNo}.pdf`);
      return null;
    });
  };

  const massRequestPickup = async () => {
    console.log("selectedOrders", selectedIds, selectedOrders);
    let noPhoneOrders = [];
    selectedOrders.forEach((val, ind) => {
      if (!val?.shipping_address?.phone) {
        noPhoneOrders.push(val.orderNo);
      }
    });
    if (noPhoneOrders.length > 0) {
      let message =
        "Please add customer phone number for " + noPhoneOrders.join(", ");

      dispatch(alertActions.error({ message, showAfterRedirect: true }));
      return false;
    } else {
      const res = await dispatch(
        orderActions.requestToShip({
          orderIdsArray: selectedIds,
        })
      );
      console.log("res", res);
      if (res?.payload?.success) {
        let message = "Pickup request sent successfully";
        dispatch(alertActions.success({ message, showAfterRedirect: true }));
        setSelectedIds([]);
        let searchObj = {
          search: search,
          status: status,
          date:
            startdate && enddate ? [moment(startdate), moment(enddate)] : [],
        };
        dispatch(orderActions.getAll(searchObj)).unwrap();
        return true;
      }
    }
  };

  async function syncOrders() {
    try {
      var importDetail = localStorage.getItem('import');
      if (importDetail) {
        importDetail = JSON.parse(importDetail);
      }
      if (importDetail && importDetail.shopifyAccessToken && importDetail.date && importDetail.linkk) {
        const resp = await dispatch(orderActions.syncOrder({
          store_url: importDetail.linkk,
          api_version: importDetail.date,
          accessToken: importDetail.shopifyAccessToken
        }));
        if (resp?.payload?.success) {
          let message = "Sync Start";
          dispatch(alertActions.success({ message, showAfterRedirect: true }));
          return true;
        }
      }else{
        let message = "Please fill details.";
        dispatch(alertActions.error({ message, showAfterRedirect: true }));
        return false;
      }
    } catch (error) {
      console.log('error', error);
      return false;
    }

  }

  return (
    <div
      className={Style.order_mainContainer}
      style={{ marginLeft: mobile ? mobile.class : "290px" }}
    >
      {console.log("0000000000", massRequestButton)}
      <div className={Style.main_container}>
        <div className={Style.filters_wrapper}>
          <h6 className={Style.filter_title}>Filters</h6>
          <hr style={{ borderColor: "#dcdcdc", opacity: 0.3 }} />
          <div className={Style.search_form}>
            <form className={Style.form_filter} onSubmit={handleSubmit}>
              <div className={Style.filter_inputWrapper}>
                <label className={Style.filter_label}>Search</label>
                <input
                  onChange={(e) => setSearch(e.target.value)}
                  value={search}
                  name="search"
                  className={Style.filter_input}
                  type="text"
                  placeholder="Order No, Merchant, etc."
                />
              </div>
              <div className={Style.filter_inputWrapper}>
                <label className={Style.filter_label}>Requested</label>
                <select
                  onChange={(e) => setPushStatus(e.target.value)}
                  value={pushStatus}
                  name="status"
                  className={Style.order_status}
                  placeholder="Order Status"
                  style={{
                    background: "#fff",
                  }}
                >
                  <option value="Order Status">Request Pickup</option>
                  <option value="pending">Pending</option>
                  <option value="requested">Requested</option>
                  <option value="pushed">Pushed</option>
                  <option value="cancelled">Cancelled</option>
                </select>
                {/* <input onChange={(e) => setStatus(e.target.value)} value={status} name="status" className={Style.filter_input} type='text' placeholder='Order Status' /> */}
              </div>
              <div className={Style.filter_inputWrapper}>
                <label className={Style.filter_label}>Status</label>
                <select
                  onChange={(e) => setStatus(e.target.value)}
                  value={status}
                  name="status"
                  className={Style.order_status}
                  placeholder="Order Status"
                  style={{
                    background: "#fff",
                  }}
                >
                  <option value="Order Status">Order Status</option>
                  <option value="pending">Pending</option>
                  <option value="requested">Requested</option>
                  <option value="pickedUp">Picked up</option>
                  <option value="onTheWay">On the way</option>
                  <option value="delivered">Delivered</option>
                  <option value="delayed">Delayed</option>
                  <option value="cancelled">Cancelled</option>
                </select>
                {/* <input onChange={(e) => setStatus(e.target.value)} value={status} name="status" className={Style.filter_input} type='text' placeholder='Order Status' /> */}
              </div>

              <div className={Style.filter_inputWrapper}>
                <label className={Style.filter_label}>Start Date</label>
                <input
                  onChange={(e) => setstartDate(e.target.value)}
                  value={startdate}
                  name="startDate"
                  className={Style.filter_input}
                  type="date"
                  placeholder="Order No, Store, Merchant, etc."
                />
              </div>
              <div className={Style.filter_inputWrapper}>
                <label className={Style.filter_label}>End Date</label>
                <input
                  onChange={(e) => setendDate(e.target.value)}
                  value={enddate}
                  name="endDate"
                  className={Style.filter_input}
                  type="date"
                  placeholder="Order No, Store, Merchant, etc."
                />
              </div>
              <div className={Style.filter_inputWrapper}>
                <button type="submit" className={Style.filter_button}>
                  Reset Filter
                </button>
              </div>
            </form>
          </div>
        </div>

        <div className={Style.content_wrapper}>
          <div className={Style.head}>
            <h1 className={Style.title}>Orders</h1>
            <div className={Style.actions}>
              {/* <div className={Style.add_search}>
                <div className={Style.search}>
                  <Input
                    sx={{ height: "100%", width: "100%", padding: "10px" }}
                    placeholder="Search by ID, Name"
                    onChange={(e) => setSearch(e.target.value)}
                    className={Style.search_field}
                  />

                  <div className={Style.serach_box}>
                    <SearchIcon className={Style.search_icons} />
                  </div>
                </div>
              </div> */}
              {selectedIds.length > 0 &&
                role === "admin" &&
                (onfleetButton ? (
                  <button
                    className={Style.download_btn}
                    disabled
                    style={{
                      cursor: "not-allowed",
                      backgroundColor: "#ccc",
                    }}
                  >
                    {/* <AllInclusiveIcon /> */}
                    Push Onfleet
                  </button>
                ) : (
                  <button className={Style.download_btn} onClick={pushOnfleet}>
                    {/* <AllInclusiveIcon /> */}
                    Push Onfleet
                  </button>
                ))}
              {selectedIds.length > 0 && (
                <button
                  onClick={() => {
                    deleteOrders();
                  }}
                  className={Style.button_import}
                >
                  {" "}
                  <DeleteIcon />
                  Delete Order
                </button>
              )}
              {selectedIds.length > 0 && (
                <button
                  onClick={async () => {
                    const res = await massRequestPickup();
                    if (res) {
                      generateLabels();
                    }
                  }}
                  className={Style.button_import}
                >
                  {" "}
                  Generate Labels
                </button>
              )}
              {/* {selectedIds.length > 0 &&
                role !== "admin" &&
                massRequestButton && (
                  <button
                    onClick={() => {
                      massRequestPickup();
                    }}
                    className={Style.button_import}
                  >
                    {" "}
                    Request Pickup
                  </button>
                )} */}
              <button className={Style.button_import} onClick={syncOrders} style={sync ? {display: "block"} : {display: "none"}}>
                Sync Order
              </button>
              <Link to="/edit" className={Style.button_import}>
                <AddIcon />
                Create Order
              </Link>
              <div
                style={{
                  position: "relative",
                  display: "inline-block",
                }}
              >
                <button
                  className={Style.button_import}
                  onClick={() => {
                    setDropDownHandle(!dropDownHandle);
                  }}
                >
                  <File /> Import&#x25BC; {/*&#x25B2;*/}
                </button>
                {dropDownHandle && (
                  <div
                    style={{
                      position: "absolute",
                      backgroundColor: "#f9f9f9",
                      minWidth: "180px",
                      boxShadow: "0px 8px 16px 0px rgba(0,0,0,0.2)",
                      zIndex: "1",
                      right: "0",
                      height: "auto",
                      top: "",
                      padding: "10px",
                      borderRadius: "5px",
                    }}
                  >
                    <button
                      onClick={() => {
                        handleOpen();
                      }}
                      style={{
                        width: "150px",
                        padding: "10px",
                        border: "none",
                        textAlign: "left",
                        textDecoration: "none",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "flex-start",
                        background: "none",
                        cursor: "pointer",
                        borderBottom: "1px solid #ccc",
                        fontSize: "14px",
                        fontFamily: "AxiformaRegular",
                      }}
                    >
                      <File />
                      <p
                        style={{
                          marginLeft: "10px",
                        }}
                      >
                        Import Shopify
                      </p>
                    </button>
                    <div>
                      <label
                        htmlFor="raised-button-file"
                        style={{
                          width: "150px",
                          padding: "10px",
                          border: "none",
                          textAlign: "left",
                          textDecoration: "none",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "flex-start",
                          background: "none",
                          cursor: "pointer",
                          borderBottom: "1px solid #ccc",
                          fontSize: "14px",
                          fontFamily: "AxiformaRegular",
                        }}
                      >
                        <File />
                        <p
                          style={{
                            marginLeft: "10px",
                          }}
                        >
                          Import CSV
                        </p>
                        <input
                          type="file"
                          name="file"
                          accept=".csv"
                          id="raised-button-file"
                          onChange={changeHandler}
                          style={{ display: "none", margin: "10px auto" }}
                        />
                      </label>
                      <span className={Style.fileName}>{fileName}</span>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
          {!(userOrders?.loading || userOrders?.error) && (
            <div className={Style.orderList}>
              <Box
                sx={{
                  height: "100%",
                  maxWidth: "100%",
                  width: "100%",
                  position: "relative",
                  background: "#fff",
                }}
              >
                <DataGrid
                  headerHeight={80}
                  rowHeight={80}
                  sx={grigStyle}
                  className={Style.data}
                  rows={rows}
                  columns={columns}
                  checkboxSelection
                  onRowSelectionModelChange={(newRowSelectionModel) => {
                    if (newRowSelectionModel.length > 0) {
                      setSelectedIds(newRowSelectionModel);
                    } else {
                      setSelectedIds([]);
                    }
                  }}
                  // selectionModel={selectedIds}
                  components={{ NoRowsOverlay, NoResultsOverlay }}
                  initialState={{
                    pagination: {
                      paginationModel: {
                        pageSize: 10,
                      },
                    },
                    columns: {
                      columnVisibilityModel: {
                        // Hide columns status and traderName, the other columns will remain visible
                        id: false,
                      },
                    },
                  }}
                  pageSizeOptions={[5, 10, 15]}
                // disableRowSelectionOnClick
                />
              </Box>
            </div>
          )}
        </div>
      </div>
      {selectedOrders?.length > 0 &&
        selectedOrders?.map((x, i) => (
          <div
            key={i}
            style={{
              display: "none",
            }}
          >
            <QRCode
              id={"myqr" + i}
              value={JSON.stringify({
                name: x.shipping_address?.name,
                address:
                  x.shipping_address?.address1 ||
                  `${x.shipping_address.city}, ${x.shipping_address.province}, ${x.shipping_address.country}, ${x.shipping_address.zip}`,
              })}
              size={250}
              style={{ margin: "auto", display: "block" }}
            />
          </div>
        ))}
      <Modal
        aria-labelledby="spring-modal-title"
        aria-describedby="spring-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            TransitionComponent: Fade,
          },
        }}
      >
        <Fade in={open}>
          <Box className={Style.modelBox}>
            <CloseIcon className={Style.closeBtn} onClick={handleClose} />
            {/* {importshopify ? <ShopifyImport handleClose={handleClose} /> : null
                            <EditOrder handleClose={handleClose} id={editorder ? editorder.id : ""} />
                            } */}
            <ShopifyImport handleClose={handleClose} />
          </Box>
        </Fade>
      </Modal>
      {userOrders?.loading && (
        <div style={{ textAlign: "center", marginTop: "40px" }}>
          <Loader />
        </div>
      )}
    </div>
  );
}

const BoxesComponent = ({ cellValues, trigger, setTrigger }) => {
  const [show, setShow] = useState(false);
  const [value, setValue] = useState(cellValues.row.noOfItems.split(" ")[0]);
  const dispatch = useDispatch();

  return (
    <>
      {show ? (
        <input
          type="number"
          value={value}
          onChange={(e) => setValue(e.target.value)}
          onBlur={() => {
            setShow(false);
          }}
          onKeyDown={async (e) => {
            if (e.key === "Enter") {
              setShow(false);
              if (Number(value) !== Number(cellValues.row.noOfItems.split(" ")[0])) {
                const res = await dispatch(
                  orderActions.updateNoOfItems({
                    id: cellValues.row.id,
                    noOfItems: value,
                  })
                ).unwrap();

                console.log("res", res);
                setTrigger(!trigger);
              }
            }
          }}
          style={{
            width: "80px",
            height: "40px",
            border: "1px solid #ccc",
            borderRadius: "5px",
            padding: "5px",
          }}
        />
      ) : (
        <p onClick={() => setShow(true)}>{cellValues.row.noOfItems}</p>
      )}
    </>
  );
};
